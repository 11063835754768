import { useState } from "react";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useJsApiLoader } from "@react-google-maps/api";
import { createClient } from "api/graphql/mutations";
import { searchClientsByOffset } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { companySelector } from "ducks/Company";
import { toggle } from "ducks/Loading";
import { PhoneNumberFormatUtil } from "utils/format";
import { debugLog } from "utils/log";
import {
  fromQueryString,
  toQueryString,
  useXGridComponents,
} from "utils/useXGridComponents";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "name",
    headerName: "名称",
    minWidth: 200,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "headOfficeWorkplacePostalCode",
    headerName: "本社郵便番号",
    valueGetter: (params) => params.row.headOfficeWorkplace?.postalCode,
    minWidth: 170,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "headOfficeWorkplacePrefecturesName",
    headerName: "本社都道府県",
    valueGetter: (params) => params.row.headOfficeWorkplace?.prefectures?.name,
    minWidth: 170,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "headOfficeWorkplaceCity",
    headerName: "本社市区町村",
    valueGetter: (params) => params.row.headOfficeWorkplace?.city,
    minWidth: 170,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "headOfficeWorkplaceStreetAddress",
    headerName: "本社町名",
    valueGetter: (params) => params.row.headOfficeWorkplace?.streetAddress,
    minWidth: 135,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "headOfficeWorkplaceOtherAddress",
    headerName: "本社町名以降",
    valueGetter: (params) => params.row.headOfficeWorkplace?.otherAddress,
    minWidth: 200,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "headOfficeWorkplacePhone",
    headerName: "本社電話番号",
    minWidth: 170,
    flex: 1,
    valueGetter: (params) => params.row?.headOfficeWorkplace?.phone,
    valueFormatter: (params) =>
      PhoneNumberFormatUtil.formatNational(params.value),
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
];

/**
 * マスタ管理の取引先の画面を表示するコンテナコンポーネントです。
 * @param {props} props
 * @callback render
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const company = useSelector(companySelector);
  useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_LICENSE_KEY,
    region: "JP",
    language: "ja",
  });
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const xGridObject = useXGridComponents(
    columns,
    searchClientsByOffset,
    {
      other: {
        supplierCompanyId: company.id,
      },
      sort: {
        direction: "desc",
        field: "createdAt",
      },
    },
    {
      ...fromQueryString(location.search),
    }
  );

  const create = (data) => {
    const { categories, headOfficeWorkplace, establishmentedOn, ...other } =
      data;

    dispatch(toggle(true));
    setIsSubmit(true);

    return API.graphql(
      graphqlOperation(createClient, {
        input: {
          categoryIds: categories.map((category) => category.id),
          establishmentedOn:
            establishmentedOn &&
            DateTime.fromJSDate(new Date(establishmentedOn)).toFormat(
              "yyyy-MM-dd"
            ),
          supplierCompanyId: company.id,
          ...other,
        },
      })
    )
      .then((res) => {
        debugLog("generalWasteLicense.onUpdate.succeeded", res);
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );

        navigate({
          pathname: `${location.pathname}/${res.data.createClient.id}`,
        });

        setOpen(false);
        xGridObject.functions.refetch();
      })
      .catch((err) => {
        debugLog("generalWasteLicense.onUpdate.failed", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
        dispatch(toggle(false));
      });
  };

  const onTransiteDetails = (items) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(xGridObject.search),
      },
      {
        replace: true,
      }
    );
    navigate(`${location.pathname}/${items[0]}`);
  };

  const navigateToBulkCreation = () => {
    navigate(
      {
        pathname: `${location.pathname}/create-bulk`,
      },
      {
        replace: true,
      }
    );
  };

  return render({
    onTransiteDetails: onTransiteDetails,
    submit: create,
    closeConfirmDialog: () => setIsOpenConfirmDialog(false),
    isOpenConfirmDialog: isOpenConfirmDialog,
    xGridObject: xGridObject,
    isSubmit: isSubmit,
    open: open,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    navigateToBulkCreation: navigateToBulkCreation,
    ...props,
  });
};
