import { useReducer } from "react";
import { confirmSignIn } from "aws-amplify/auth";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useDispatch, useSelector } from "react-redux";
import { AUTHSTATES, selector, set } from "ducks/Auth";
import { debugLog } from "utils/log";

const initialState = {
  loading: false,
  locked: false,
  error: null,
};

const isTruthyString = (value) => {
  return (
    value &&
    typeof value.toLowerCase === "function" &&
    value.toLowerCase() === "true"
  );
};

const actions = {
  SUBMIT_BEGIN: "ConfirmSignIn/submit/begin",
  SUBMIT_SUCCEEDED: "ConfirmSignIn/submit/succeeded",
  SUBMIT_FAILED: "ConfirmSignIn/submit/failed",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SUBMIT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case actions.SUBMIT_SUCCEEDED:
      return {
        ...state,
        loading: false,
      };
    case actions.SUBMIT_FAILED:
      return {
        ...state,
        loading: false,
        locked: action.payload?.locked ?? false,
        error: action.payload.error,
      };
    default:
      break;
  }
};

/**
 * 有効な認証状態
 */
const VALID_AUTO_STATES = ["confirmSignIn"];

/**
 * 確認コードを入力する画面を表示するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { authState } = useSelector(selector);
  const appDispatch = useDispatch();

  const onStateChange = (state) => {
    appDispatch(set(state));
  };

  const submit = (code) => {
    dispatch({ type: actions.SUBMIT_BEGIN });
    let user = props.authData;
    // TOTPは使用しないのでSMS固定
    confirmSignIn(user, code, "SMS")
      .then(() => {
        fetchUserAttributes().then((data) => {
          const emailVerified = isTruthyString(data.email_verified);
          if (emailVerified) {
            onStateChange(AUTHSTATES.AUTHENTICATED);
          } else {
            onStateChange(AUTHSTATES.CONFIRM_SIGN_IN);
          }
          dispatch({ type: actions.SUBMIT_SUCCEEDED });
        });
      })
      .catch((err) => {
        debugLog("Full Error Object:", err);
        debugLog("Error Message:", err.message);
        debugLog("Error Name:", err.name);
        debugLog("Stack Trace:", err.stack);

        switch (err.name) {
          case "CodeMismatchException":
            dispatch({
              type: actions.SUBMIT_FAILED,
              payload: {
                error: "入力されたコードに誤りがあります。",
              },
            });
            break;
          case "LimitExceededException":
            dispatch({
              type: actions.SUBMIT_FAILED,
              payload: {
                error: "入力規定回数を超えました。",
                locked: true,
              },
            });
            break;
          case "ExpiredCodeException":
            dispatch({
              type: actions.SUBMIT_FAILED,
              payload: {
                error: "入力されたコードの有効期限が切れています。",
                locked: true,
              },
            });
            break;
          default:
            debugLog(err);
            dispatch({
              type: actions.SUBMIT_FAILED,
              payload: {
                error: "エラーが発生しました。",
                locked: true,
              },
            });
            break;
        }
      });
  };

  return render({
    onTransiteLogin: () => onStateChange(AUTHSTATES.SIGN_IN),
    onSubmit: submit,
    onError: debugLog,
    loading: state.loading,
    locked: state.locked,
    error: state.error,
    isValid: VALID_AUTO_STATES.includes(authState),
    ...props,
  });
};
