import React from "react";
import {
  Avatar,
  Button,
  Stack,
  DialogContentText,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import { DomainOutlined } from "@mui/icons-material";

import { FullScreenDialog, ConfirmDialog } from "views/molecules";
import { PartnerDetailTabs } from "views/organisms/Masters/Partner";
import { PartnerForm } from "views/organisms/Masters/Partner/PartnerDetailTabs/ChildComponents/PartnerForm";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import { Mark } from "views/atoms";

/**
 * 取引先詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  tab,
  onChangeTab,
  value,
  open,
  onOpenDialog,
  onCloseDialog,
  onChangeDataSubmit,
  companyCategories,
  isSubmit,
  confirmRemoveDialog,
  onDelete,
  checked,
  onToggleChecked,
  loading,
}) => (
  <div>
    <DetailsFrame
      caption={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              marginRight: 3,
              width: 58,
              height: 58,
            }}
          >
            <DomainOutlined sx={{ fontSize: "2.5rem" }} />
          </Avatar>
          <div>
            {companyCategories.map((companyCategory, index) =>
              companyCategory.name === "排出事業者" &&
              value?.categories
                ?.map((item) => item.id)
                ?.includes(companyCategory.id) ? (
                <Mark
                  text="排出事業者"
                  backgroundColor={"#2196f3"}
                  key={index}
                  style={{
                    paddingLeft: "3px",
                  }}
                />
              ) : companyCategory.name === "収集運搬事業者" &&
                value?.categories
                  ?.map((item) => item.id)
                  ?.includes(companyCategory.id) ? (
                <Mark
                  text="収集運搬事業者"
                  backgroundColor={"#0BB929"}
                  key={index}
                  style={{
                    paddingLeft: "3px",
                  }}
                />
              ) : companyCategory.name === "処分事業者" &&
                value?.categories
                  ?.map((item) => item.id)
                  ?.includes(companyCategory.id) ? (
                <Mark
                  text="処分事業者"
                  backgroundColor={"#0BB929"}
                  key={index}
                  style={{
                    paddingLeft: "3px",
                  }}
                />
              ) : (
                <React.Fragment key={index}></React.Fragment>
              )
            )}
            <Caption title={value?.name} />
          </div>
        </div>
      }
      actions={
        tab === 2 && (value ? value?.state !== "removed" : false) ? (
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="error"
              onClick={() => confirmRemoveDialog.toggle(true)}
              disabled={!value}
            >
              {"削除する"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onOpenDialog}
              disabled={!value}
            >
              {"編集する"}
            </Button>
          </Stack>
        ) : (
          <></>
        )
      }
    >
      <PartnerDetailTabs
        tabNumber={tab}
        value={value}
        onChangeTab={onChangeTab}
      />
      <FullScreenDialog
        onClose={onCloseDialog}
        title={`${value?.name}の編集`}
        textConfirm={"保存"}
        open={open}
        formId="partner-form"
        isSubmit={isSubmit}
        disabled={isSubmit}
      >
        <PartnerForm
          id="partner-form"
          value={value}
          onSubmit={onChangeDataSubmit}
        />
      </FullScreenDialog>
    </DetailsFrame>
    <ConfirmDialog
      title="取引先の削除"
      message={
        <Box>
          <DialogContentText>
            この取引先を削除してよろしいですか？
          </DialogContentText>
          <DialogContentText>この操作は元に戻せません。</DialogContentText>
          <DialogContentText>
            関連するデータはすべて削除されます。
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={onToggleChecked}
                color="primary"
              />
            }
            label="注意事項に合意しました。"
          />
        </Box>
      }
      disabled={loading}
      open={confirmRemoveDialog.open}
      positiveText={"削除"}
      negativeText={"キャンセル"}
      onPositive={onDelete}
      positiveDisabled={!checked}
      onNegative={() => confirmRemoveDialog.toggle(false)}
    />
  </div>
);
