/**
 * 確認ダイアログの表示を行うコンテナコンポーネントです
 */
export const Container = ({
  render,
  title = "確認登録",
  message = "登録しますか？",
  open,
  onPositive,
  onNegative,
  positiveText = "登録",
  negativeText = "キャンセル",
  disabled = false,
  positiveDisabled = false,
  negativeDisabled = false,
  ...props
}) => {
  return render({
    title: title,
    message: message,
    open: open,
    onPositive: onPositive,
    onNegative: onNegative,
    positiveText: positiveText,
    negativeText: negativeText,
    disabled: disabled,
    positiveDisabled: positiveDisabled,
    negativeDisabled: negativeDisabled,
    ...props,
  });
};
