import React from "react";
import { Button, CircularProgress, Link, Typography, Box } from "@mui/material";
import { AuthenticationTemplate } from "../../../organisms";
import { Controller } from "react-hook-form";
import { FieldDecorateFrame, InputField } from "../../../molecules";
import PasswordTextField from "views/atoms/PasswordTextField";

/**
 * ログイン画面を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <AuthenticationTemplate>
    <Box mb={2}>
      <form onSubmit={props.handleSubmit} noValidate>
        <Box mb={2}>
          <Controller
            name="username"
            rules={{ required: "メールアドレスを入力してください。" }}
            control={props.control}
            render={({
              field: { value, onChange, ref },
              fieldState: { invalid, isTouched, isDirty, error },
            }) => (
              <InputField
                value={value}
                onChange={(event) => {
                  onChange(event);
                  props.clearError();
                }}
                label="メールアドレス"
                error={Boolean(error?.message)}
                helperText={error?.message}
                inputRef={ref}
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <FieldDecorateFrame label="パスワード">
            {(params) => (
              <Controller
                name="password"
                rules={{ required: "パスワードを入力してください。" }}
                control={props.control}
                render={({
                  field: { value, onChange, ref },
                  fieldState: { error },
                }) => (
                  <PasswordTextField
                    value={value}
                    onChange={(event) => {
                      onChange(event);
                      props.clearError();
                    }}
                    fullWidth={true}
                    error={Boolean(error?.message)}
                    helperText={error?.message}
                    inputRef={ref}
                  />
                )}
              />
            )}
          </FieldDecorateFrame>
        </Box>
        <div
          style={{
            position: "relative",
            width: "fit-content",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            disabled={props.isLoading}
            className={props.classes.buttonStyle}
          >
            ログイン
          </Button>
          {props.isLoading && (
            <CircularProgress
              size={24}
              className={props.classes.buttonProgress}
            />
          )}
          {props.error && (
            <Typography color="error" paragraph>
              {props.error}
            </Typography>
          )}
        </div>
      </form>
    </Box>
    <Box>
      <Link
        href="#"
        onClick={props.handleClickForgotPassword}
        className={props.classes.linkRouter}
      >
        パスワードをお忘れの方はこちら
      </Link>
    </Box>
  </AuthenticationTemplate>
);
