import { format } from "date-fns";
export const sampleData = [
  {
    id: 1,
    termOfAgreementStartedOn: format(Date.now(), "yyyy-MM-dd"),
    name: "ABC株式会社",
    type: {
      name: "二者契約",
    },
    partyCompanies: [
      {
        id: 1,
        name: "XYZ株式会社",
      },
      {
        id: 2,
        name: "DEF株式会社",
      },
    ],
  },
  {
    id: 2,
    termOfAgreementStartedOn: format(Date.now(), "yyyy-MM-dd"),
    name: "GHI合同会社",
    type: {
      name: "二者契約",
    },
    partyCompanies: [
      {
        id: 1,
        name: "JKL株式会社",
      },
      {
        id: 2,
        name: "MNO株式会社",
      },
    ],
  },
  {
    id: 3,
    termOfAgreementStartedOn: format(Date.now(), "yyyy-MM-dd"),
    name: "PQR株式会社",
    type: {
      name: "二者契約",
    },
    partyCompanies: [
      {
        id: 1,
        name: "STU株式会社",
      },
      {
        id: 2,
        name: "VWX株式会社",
      },
    ],
  },
  {
    id: 4,
    termOfAgreementStartedOn: format(Date.now(), "yyyy-MM-dd"),
    name: "YZA株式会社",
    type: {
      name: "二者契約",
    },
    partyCompanies: [
      {
        id: 1,
        name: "BCD株式会社",
      },
      {
        id: 2,
        name: "EFG株式会社",
      },
    ],
  },
  {
    id: 5,
    termOfAgreementStartedOn: format(Date.now(), "yyyy-MM-dd"),
    name: "HIJ合同会社",
    type: {
      name: "二者契約",
    },
    partyCompanies: [
      {
        id: 1,
        name: "KLM株式会社",
      },
      {
        id: 2,
        name: "NOP株式会社",
      },
    ],
  },
];
