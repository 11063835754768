import React from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  List,
  Avatar,
  Paper,
} from "@mui/material";
import TimelineContent from "@mui/lab/TimelineContent";
import { TimelineConnector, TimelineSeparator } from "@mui/lab";
import { LoadingSkeleton } from "views/pages/Dashboard/Components";
import { format } from "date-fns";

const STATUS = Object.freeze({
  CURRENT: "current",
  NEXT: "next",
  NOT_STARTED: "not_started",
  NO_VEHICLE_ALLOCATED: "no_vehicle_allocated",
});

const TimeLineTitle = ({ status, arrivedAt }) => {
  let backgroundColor = "";
  let message = "";
  switch (status) {
    case STATUS.CURRENT:
      message = format(
        new Date(arrivedAt?.replace("Z", "")),
        "yyyy/MM/dd HH:mm"
      );
      backgroundColor = "#2E7D32";
      break;
    case STATUS.NEXT:
      message = "Next";
      backgroundColor = "#EF6C00";
      break;
    case STATUS.NO_VEHICLE_ALLOCATED:
      message = "車輛が未割り当てのためスタート地点が不明です。";
      backgroundColor = "error.main";
      break;
    case STATUS.NOT_STARTED:
      message = "START";
      backgroundColor = "#2E7D32";
      break;
    default:
      break;
  }
  return (
    <Typography
      sx={{
        fontSize: 14,
        fontWeight: 600,
        color: "#FFFFFF",
        width: "fit-content",
        px: 3,
        borderRadius: 1,
        py: 0.2,
        backgroundColor: backgroundColor,
      }}
    >
      {message}
    </Typography>
  );
};

/**
 * DriverRowコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {number} props.index - ドライバーのインデックス
 * @param {number} props.quantity - 量
 * @param {string} props.address - 住所
 * @param {string} props.workspaceName - 作業場所の名前
 * @param {number} props.sequenceNumber - シーケンス番号
 * @param {string} props.arrivedAt - 到着時間
 */
const DriverRow = ({
  index,
  quantity,
  address,
  workspaceName,
  sequenceNumber,
  arrivedAt,
  status,
}) => {
  const getSequenceNumber = () => {
    switch (status) {
      case STATUS.CURRENT:
        return sequenceNumber + 1;
      case STATUS.NEXT:
        return sequenceNumber + 1;
      case STATUS.NO_VEHICLE_ALLOCATED:
        return 0;
      case STATUS.NOT_STARTED:
        return 0;
      default:
        return "";
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: 80,
          pl: 1,
          pr: 2,
        }}
      >
        <TimelineSeparator>
          <TimelineConnector color="#000000" />
          <Avatar
            sx={{
              bgcolor: index === 0 ? "#283593" : "#F0F0F7",
              border: "1px solid #283593",
              color: index === 0 ? "#F0F0F7" : "#283593",
            }}
          >
            {getSequenceNumber()}
          </Avatar>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent display="flex" flexDirection="row" px={2}>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent={"center"}
          >
            <TimeLineTitle status={status} arrivedAt={arrivedAt} />
            {workspaceName && (
              <>
                <Typography
                  fontSize={20}
                  fontWeight={800}
                  color="text.secondary"
                >
                  {workspaceName}
                </Typography>
                <Typography
                  fontSize={15}
                  fontWeight={700}
                  color="text.secondary"
                >
                  〒 {address}
                </Typography>
              </>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width={40}
          >
            {status === STATUS.NOT_STARTED ||
            status === STATUS.NO_VEHICLE_ALLOCATED ? null : (
              <Typography
                color="text.secondary"
                fontSize={20}
                fontWeight={900}
                sx={{
                  textDecoration: "none",
                  position: "relative",
                  "&:after": {
                    content: "''",
                    width: "100%",
                    position: "absolute",
                    left: 0,
                    color: index === 0 ? "#2E7D32" : "#EF6C00",
                    bottom: -3,
                    borderWidth: 3,
                    borderBottomStyle: "solid",
                  },
                }}
              >
                {quantity}Kg
              </Typography>
            )}
          </Box>
        </TimelineContent>
      </Box>
    </>
  );
};

/**
 * Presententerコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Function} props.onMouseEnter - マウスエンターイベントのコールバック関数
 * @param {Array} props.drivers - ドライバーの配列
 * @param {Function} props.handleDriverSelect - ドライバー選択時のコールバック関数
 * @param {Object} props.selectedDriver - 選択されたドライバー
 * @param {boolean} props.loading - ローディング状態かどうか
 * @param {Array} props.data - 表示するデータの配列
 * @param {boolean} props.loadingData - データのローディング状態かどうか
 */
export const Presententer = ({
  onMouseEnter,
  drivers,
  handleDriverSelect,
  selectedDriver,
  loading,
  data,
  loadingData,
}) => {
  if (loading) return <LoadingSkeleton size={400} loading />;

  return (
    <Box
      sx={{
        p: 2,
        width: "100%",
        height: "100%",
      }}
    >
      <Box display="flex">
        <Typography textAlign="left" color="text.secondary" fontWeight={600}>
          ユーザータイムライン
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            flex: 1,
            gap: 2,
          }}
        >
          <InputLabel
            sx={{
              color: "text.secondary",
              fontWeight: "bold",
            }}
            id="driver-select-label"
          >
            ユーザー
          </InputLabel>
          <Select
            labelId="driver-select-label"
            id="driver-select"
            sx={{
              width: 150,
            }}
            size="small"
            onChange={handleDriverSelect}
            value={selectedDriver}
          >
            {drivers?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Box>
      <Paper
        sx={{
          mt: 1,
          height: `calc(100% - 40px)`,
        }}
      >
        {loadingData && (
          <Box
            sx={{ p: 1, textAlign: "center" }}
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <LoadingSkeleton loading />
          </Box>
        )}
        {!loadingData && data?.length === 0 && (
          <Box
            sx={{ p: 1, textAlign: "center" }}
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography textAlign="center" color="text.secondary">
              {selectedDriver
                ? "タスクはありません"
                : "ドライバーを選択してください。"}
            </Typography>
          </Box>
        )}
        {!loadingData && data?.length ? (
          <List
            sx={{
              height: 210,
              overflowY: "auto",
              overflowX: "hidden",
              p: 1,
            }}
          >
            {data.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <DriverRow
                    onMouseEnter={onMouseEnter}
                    address={`${item.workplace?.postalCode} ${
                      item.workplace?.prefectures?.name +
                      item.workplace?.city +
                      item.workplace?.streetAddress +
                      item.workplace?.otherAddress
                    }`}
                    index={index}
                    quantity={item.wasteInKg}
                    workspaceName={item.workplace?.name}
                    sequenceNumber={item.order}
                    arrivedAt={item.arrivedAt}
                    status={item.status}
                  />
                </React.Fragment>
              );
            })}
          </List>
        ) : null}
      </Paper>
    </Box>
  );
};
