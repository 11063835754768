import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * 確認ダイアログの表示を行うプレゼンテーションコンポーネントです
 */
export const Presententer = (props) => {
  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.message}</DialogContent>
      {props.showProgress && (
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="success" />
        </DialogContent>
      )}
      <DialogActions>
        {props.negativeText && (
          <Button
            onClick={props.onNegative}
            color="error"
            disabled={props.disabled || props.negativeDisabled}
          >
            {props.negativeText}
          </Button>
        )}
        {props.positiveText && (
          <Button
            onClick={props.onPositive}
            color="primary"
            disabled={props.disabled || props.positiveDisabled}
            autoFocus
          >
            {props.positiveText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
