import yup, { textSchema, urlSchema, dateSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  name: textSchema.required(),
  corporateNumber: yup
    .string()
    .corporateNumberCheckDigits()
    .transform((value) => (value === "" ? null : value))
    .nullable()
    .length(13)
    .matches(/^[0-9]+$/),
  representativeName: textSchema,
  establishmentedOn: dateSchema,
  url: urlSchema,
  headOffice: yup
    .object()
    .required(),
});
