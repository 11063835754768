import React, { useState, useEffect } from "react";
import { Typography, Stack, Alert, Chip, Tooltip, Box } from "@mui/material";
import { DataGridPro, GridCell, useGridApiRef } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

import { industrySectorsSelector } from "ducks/IndustrySectors";
import { companySelector } from "ducks/Company";

import { ModalPopup, ClientSelector } from "views/molecules";

const businessPartner = "business-partner";
const businessWorkPlace = "business-workplaces";

const Columns = [
  {
    value: businessPartner,
    columns: [
      {
        field: "row",
        headerName: "行番号",
        width: 80,
      },
      {
        field: "categories",
        headerName: "事業者区分(必須)",
        width: 230,
      },
      { field: "name", headerName: "名称(必須)", width: 150 },
      { field: "corporateNumber", headerName: "法人番号", width: 150 },
      { field: "representativeName", headerName: "代表者", width: 150 },
      {
        field: "establishmentedOn",
        headerName: "設立日",
        width: 150,
      },
      { field: "url", headerName: "企業ホームページ", width: 150 },
      { field: "remarks", headerName: "企業概要", flex: 1 },
    ],
  },
  {
    value: businessWorkPlace,
    columns: [
      {
        field: "row",
        headerName: "行番号",
        width: 80,
      },
      {
        field: "companyName",
        headerName: "会社名(必須)",
        width: 180,
      },
      {
        field: "code",
        headerName: "固有コード",
        width: 100,
      },
      { field: "categories", headerName: "事業場区分", width: 180 },
      { field: "industrySectorId", headerName: "業種", width: 180 },
      { field: "name", headerName: "名称(必須)", width: 180 },
      { field: "postalCode", headerName: "郵便番号(必須)", width: 115 },
      { field: "prefectures", headerName: "都道府県(必須)", width: 115 },
      { field: "city", headerName: "市区町村(必須)", width: 180 },
      { field: "streetAddress", headerName: "町域(必須)", width: 180 },
      { field: "otherAddress", headerName: "町域以降(必須)", width: 180 },
      { field: "phone", headerName: "電話番号", width: 120 },
      { field: "fax", headerName: "FAX番号", width: 120 },
      { field: "email", headerName: "メールアドレス", width: 150 },
      { field: "remarks", headerName: "特記事項", width: 180 },
    ],
  },
];

/**
 * Excel ファイルで発生したエラーを表形式で表示するために使用されます。
 * @param {rows} フォーマットされたエラー行
 * @param {importType}
 * @param {isErrorTable} エラーがあるかどうか
 * @returns
 */

const BulkCreateTable = ({
  rows,
  importType,
  isErrorTable,
  errors,
  setRows,
  setErrors,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [open, setOpen] = useState("");

  const columns = Columns.find(
    (item) => item.value === importType.value
  ).columns;

  const dataGridRef = useGridApiRef();
  const industrySectors = useSelector(industrySectorsSelector);
  const company = useSelector(companySelector);

  useEffect(() => {
    if (errors.length) {
      const errorExists = errors.some((item) => item.errors.length);

      if (!errorExists) {
        setRows(
          errors.map((item) => {
            delete item.errors;
            return item;
          })
        );
        setErrors([]);
      }
    }
  }, [errors, setRows, setErrors]);

  const setBelongInCompany = (client) => {
    const address = client.headOfficeWorkplace
      ? `${client.headOfficeWorkplace.postalCode}${client.headOfficeWorkplace.prefectures.name}${client.headOfficeWorkplace.city}${client.headOfficeWorkplace.streetAddress}${client.headOfficeWorkplace.otherAddress}`
      : "";
    const result = errors.map((item) => {
      if (item.id === open) {
        return {
          ...item,
          belongInCompanyId: client.companyId,
          companyName: address?.length
            ? `${client.name}(${address})`
            : client.name,
          errors: [],
        };
      }
      return item;
    });

    setErrors(result);
  };

  const handleClientSelect = (rowId) => {
    setOpen(rowId);
  };

  const handleModelClose = () => {
    setOpen("");
  };

  const onSelectClient = (client) => {
    setBelongInCompany(client[0]);
    setOpen("");
  };

  const handleOwnerCompanySelect = () => {
    setBelongInCompany({
      ...company,
      companyId: company.id,
    });
    setOpen("");
  };

  const renderCell = (params) => {
    const { value, rowId } = params;
    const row = rows.find((item) => item.id === rowId);
    if (
      row?.errors?.length &&
      row.errors?.some((error) => error.field === params.field)
    ) {
      isFirstRender &&
        dataGridRef.current &&
        dataGridRef.current.scrollToIndexes({
          rowIndex: 0,
          colIndex: columns.findIndex((item) => item.field === params.field),
        });
      isFirstRender && setIsFirstRender(false);
      const errorRow = row.errors?.find((err) => err.field === params.field);

      return (
        <GridCell {...params}>
          <Tooltip title={errorRow?.error} followCursor>
            <Alert
              onClick={
                errorRow?.duplicate
                  ? () => handleClientSelect(rowId)
                  : undefined
              }
              sx={{
                width: "100%",
                px: 0,
                height: 35,
                overflow: "hidden",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: errorRow?.duplicate ? "pointer" : "inherit",
                textDecoration: errorRow?.duplicate ? "underline" : "none",
                color: errorRow?.duplicate ? "primary.main" : "inherit",
                textUnderlineOffset: 3,
              }}
              variant="outlined"
              severity="error"
            >
              {value}
            </Alert>
          </Tooltip>
        </GridCell>
      );
    }
    if (
      (importType.value === businessPartner && params.field === "categories") ||
      (importType.value === businessWorkPlace && params.field === "categories")
    ) {
      return (
        <GridCell {...params}>
          <Stack display="flex" flexDirection="row" gap={1}>
            {params.value?.map((item) => (
              <Chip
                label={typeof item === "string" ? item : item.name}
                size="small"
              />
            ))}
          </Stack>
        </GridCell>
      );
    }

    if (
      importType.value === businessWorkPlace &&
      params.field === "prefectures"
    ) {
      return (
        <GridCell {...params}>
          <Stack display="flex" flexDirection="row" gap={1}>
            {typeof params.value === "string"
              ? params.value
              : params.value?.name}
          </Stack>
        </GridCell>
      );
    }

    if (
      importType.value === businessWorkPlace &&
      params.field === "industrySectorId"
    ) {
      return (
        <GridCell {...params}>
          {industrySectors?.find((item) => item.id === value)?.middleClass
            .name ?? value}
        </GridCell>
      );
    }

    if (params.field === "establishmentedOn") {
      return (
        <GridCell {...params}>
          {value ? DateTime.fromISO(value).toFormat("yyyy-MM-dd") : ""}
        </GridCell>
      );
    }

    return <GridCell {...params}>{value}</GridCell>;
  };

  return (
    <>
      {isErrorTable ? (
        <Stack sx={{ width: "100%" }}>
          <Alert variant="outlined" severity="warning">
            <Typography color="success.main">
              ファイルは無効です。エラーを修正してファイルを再アップロードしてください。
            </Typography>
            <Typography color="success.main">
              合計エラー: {rows.length}
            </Typography>
          </Alert>
        </Stack>
      ) : null}
      <Stack
        sx={{
          width: "100%",
          mt: 1,
          height: "100%",
        }}
      >
        <DataGridPro
          rows={rows}
          components={{
            Cell: renderCell,
          }}
          apiRef={dataGridRef}
          rowHeight={40}
          columns={columns}
          pageSize={20}
          pagination
          columnBuffer={Number.MAX_SAFE_INTEGER}
        />
      </Stack>
      <ModalPopup open={Boolean(open)} onClose={handleModelClose}>
        <Box pb={2} sx={{ height: "80vh" }}>
          <ClientSelector
            selectableOwn
            onSelected={onSelectClient}
            onOwnSelected={handleOwnerCompanySelect}
            fixedOptions={{
              filter: {
                and: [
                  {
                    name: {
                      eq: errors.find((item) => item.id === open)?.companyName,
                    },
                  },
                ],
              },
            }}
          />
        </Box>
      </ModalPopup>
    </>
  );
};

export default BulkCreateTable;

BulkCreateTable.propTypes = {
  rows: PropTypes.array.isRequired,
  importType: PropTypes.object.isRequired,
  isErrorTable: PropTypes.bool.isRequired,
};
