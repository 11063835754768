import { useCallback, useEffect, useReducer, useState, useRef } from "react";
import { MoreVert } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { IconButton } from "@mui/material";
import { DateTime } from "luxon";
import { debugLog } from "utils/log";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { getRegularlyWasteCollectionSchedules } from "api/graphql/queries";
import {
  createIncreasedRegularlyWasteCollectionSchedule,
  removeIncreasedRegularlyWasteCollectionSchedule,
  reduceRegularlyWasteCollectionSchedule,
} from "api/graphql/mutations";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { add as addAlert } from "ducks/Alert";

const getThisMonth = () => {
  const now = new Date();
  return DateTime.fromJSDate(new Date(now.getFullYear(), now.getMonth(), 1));
};

const isThisMonth = (date) => {
  const this_month = getThisMonth();
  return this_month.year === date.year && this_month.month === date.month;
};

const SCHEDULE_STATES = {
  VIRTUAL: "virtual",
  INCREASE: "increase",
  REDUCE: "reduce",
  ACTIVE: "active",
};

const initialState = {
  rows: null,
  current_month: getThisMonth(),
  anchorEl: null,
  selectedValue: null,
  open: false,
  isSubmit: false,
};

const ActionTypes = {
  SET_SCHEDULED: "scheduledCollects/set_scheduled",
  SET_PREV: "scheduledCollects/set_prev",
  SET_NEXT: "scheduledCollects/set_next",
  SET_CURRENT: "scheduledCollects/set_current",
  OPEN_MENU: "scheduledCollects/open_menu",
  CLOSE_MENU: "scheduledCollects/close_menu",
  OPEN_DIALOG: "scheduledCollects/open_dialog",
  CLOSE_DIALOG: "scheduledCollects/close_dialog",
  SET_SUBMIT_STATE: "scheduleCollects/set_submit_state",
};

const reducerFunc = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_SCHEDULED: {
      return {
        ...state,
        selectedValue: null,
        rows: action.payload,
      };
    }
    case ActionTypes.SET_PREV: {
      const prev_month = state.current_month.minus({ months: 1 });
      return {
        ...state,
        current_month: prev_month,
        isThisMonth: isThisMonth(prev_month),
      };
    }
    case ActionTypes.SET_NEXT: {
      const next_month = state.current_month.plus({ months: 1 });
      return {
        ...state,
        current_month: next_month,
        isThisMonth: isThisMonth(next_month),
      };
    }
    case ActionTypes.SET_CURRENT: {
      const now = getThisMonth();
      return {
        ...state,
        current_month: now,
        isThisMonth: true,
      };
    }
    case ActionTypes.OPEN_MENU: {
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
        selectedValue: action.payload.selectedValue,
      };
    }
    case ActionTypes.CLOSE_MENU: {
      return {
        ...state,
        anchorEl: null,
      };
    }
    case ActionTypes.OPEN_DIALOG:
      return {
        ...state,
        open: true,
      };
    case ActionTypes.CLOSE_DIALOG:
      return {
        ...state,
        open: false,
        anchorEl: null,
        selectedValue: null,
      };
    case ActionTypes.SET_SUBMIT_STATE:
      return {
        ...state,
        isSubmit: action.payload,
      };
    default:
      return state;
  }
};

/**
 * 予定された回収情報を表示するコンポーネントです
 */
export const Container = ({
  render,
  value,
  refetchWasteOrder,
  setRefetchWasteOrder = (data) => debugLog(data),
  onChange = (data) => debugLog(data),
  ...props
}) => {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const formRef = useRef(null);
  const { id } = useParams();
  const reduxDispatch = useDispatch();
  const [
    { rows, current_month, anchorEl, selectedValue, open, isSubmit },
    dispatch,
  ] = useReducer(reducerFunc, initialState);

  const handleOpenMenu = (data) => (event) => {
    dispatch({
      type: ActionTypes.OPEN_MENU,
      payload: { anchorEl: event.currentTarget, selectedValue: data.row },
    });
  };
  const handleCloseMenu = () => {
    dispatch({
      type: ActionTypes.CLOSE_MENU,
    });
  };

  const handleCheckeBox = (event, id) => {
    const value = event.target.checked;
    if (value) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((row) => row !== id));
    }
  };

  const handleAllCheckedToggle = () => {
    if (isAllChecked) {
      setSelectedRows([]);
    } else {
      setSelectedRows(
        rows.schedules
          .map((row, i) => {
            if (row.scheduleState === SCHEDULE_STATES.VIRTUAL || row.scheduleState === SCHEDULE_STATES.ACTIVE) {
              return i;
            }
            return null;
          })
          .filter((row) => row !== null)
      );
    }
    setIsAllChecked((prev) => !prev);
  };

  const columns = [
    {
      field: "",
      width: 80,
      sortable: false,
      disableColumnMenu: !selectedRows.length,
      renderHeader: () => (
        <Checkbox
          size="medium"
          checked={isAllChecked}
          onChange={handleAllCheckedToggle}
        />
      ),
      renderCell: (params) => {
        const isDisabled = ![
          SCHEDULE_STATES.VIRTUAL,
          SCHEDULE_STATES.ACTIVE,
        ].includes(params?.row?.scheduleState);
        return (
          <Checkbox
            size="medium"
            disabled={isDisabled}
            checked={selectedRows.includes(params.id)}
            onChange={(event) => handleCheckeBox(event, params.id)}
          />
        );
      },
    },
    {
      field: "scheduleDate",
      headerName: "回収日",
      width: 130,
      valueFormatter: (params) => {
        return params?.value
          ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
          : "";
      },
    },
    {
      field: "times",
      headerName: "回収時間",
      width: 160,
      renderCell: (params) => {
        const scheduleTimeRangeStart = params?.row?.scheduleTimeRangeStart;
        const scheduleTimeRangeEnd = params?.row?.scheduleTimeRangeEnd;
        return `${
          scheduleTimeRangeStart
            ? DateTime.fromISO(scheduleTimeRangeStart).toFormat("HH:mm")
            : ""
        } ～ ${
          scheduleTimeRangeEnd
            ? DateTime.fromISO(scheduleTimeRangeEnd).toFormat("HH:mm")
            : ""
        }`;
      },
    },
    {
      field: "scheduleState",
      headerName: "ステータス",
      width: 160,
      renderCell: (params) => {
        switch (params.row.scheduleState) {
          case SCHEDULE_STATES.VIRTUAL:
          case SCHEDULE_STATES.ACTIVE:
            return "通常定期";
          case SCHEDULE_STATES.INCREASE:
            return "増便";
          case SCHEDULE_STATES.REDUCE:
            return "減便";
          default:
            return "";
        }
      },
    },
    {
      field: "remarks",
      headerName: "備考",
      flex: 1,
      valueFormatter: (params) => {
        return params.value ? params.value?.split("\n").join(" ") : "";
      },
    },
    {
      field: "id",
      headerName: " ",
      width: 10,
      filterable: false,
      renderCell: (params) => {
        return (
          <IconButton onClick={handleOpenMenu(params)}>
            <MoreVert />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_CURRENT,
      payload: value,
    });
  }, [value]);

  const loadSchedules = useCallback(() => {
    dispatch({
      type: ActionTypes.SET_SUBMIT_STATE,
      payload: true,
    });

    API.graphql(
      graphqlOperation(getRegularlyWasteCollectionSchedules, {
        id: id,
        year: current_month.year,
        month: current_month.month,
      })
    )
      .then((res) => {
        dispatch({
          type: ActionTypes.SET_SCHEDULED,
          payload: res.data.getRegularlyWasteCollectionSchedules,
        });
      })
      .catch((err) => {
        debugLog("定期回収情報の取得に失敗: ", err);
      })
      .finally(() => {
        dispatch({
          type: ActionTypes.SET_SUBMIT_STATE,
          payload: false,
        });
      });
  }, [id, current_month]);

  useEffect(() => {
    loadSchedules();
  }, [loadSchedules]);

  useEffect(() => {
    if (refetchWasteOrder) {
      loadSchedules();
      setRefetchWasteOrder(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchWasteOrder]);

  const handleClickLeft = () => {
    dispatch({
      type: ActionTypes.SET_PREV,
      payload: value,
    });
  };

  const handleClickRight = () => {
    dispatch({
      type: ActionTypes.SET_NEXT,
      payload: value,
    });
  };

  const handleThisMonth = () => {
    dispatch({
      type: ActionTypes.SET_CURRENT,
      payload: value,
    });
  };

  const handleConfirm = () => {
    formRef.current.submit();
  };

  const handleCloseDialog = () => {
    dispatch({
      type: ActionTypes.CLOSE_DIALOG,
    });
  };

  const exeDelete = (data) => {
    let deleteAPI;
    if (data.scheduleState === SCHEDULE_STATES.INCREASE) {
      deleteAPI = API.graphql(
        graphqlOperation(removeIncreasedRegularlyWasteCollectionSchedule, {
          id: data.scheduleId,
        })
      );
    } else if (
      data.scheduleState === SCHEDULE_STATES.VIRTUAL ||
      data.scheduleState === SCHEDULE_STATES.ACTIVE
    ) {
      // UNDONE: apiができてないのでいったん放置
      deleteAPI = API.graphql(
        graphqlOperation(reduceRegularlyWasteCollectionSchedule, {
          input: {
            id: data.cycleId,
            scheduleDate: DateTime.fromFormat(
              data.scheduleDate,
              "yyyy-MM-dd"
            ).toISODate(),
            remarks: data.remarks,
          },
        })
      );
    }

    dispatch({
      type: ActionTypes.SET_SUBMIT_STATE,
      payload: true,
    });

    deleteAPI
      .then((res) => {
        reduxDispatch(
          addAlert({
            value: "削除しました。",
            severity: "success",
          })
        );
        dispatch({
          type: ActionTypes.CLOSE_MENU,
        });
        loadSchedules();
      })
      .catch((err) => {
        reduxDispatch(
          addAlert({
            value: "エラーが発生したため、削除できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch({
          type: ActionTypes.SET_SUBMIT_STATE,
          payload: false,
        });
      });
  };

  const handleSubmit = (data) => {
    dispatch({
      type: ActionTypes.SET_SUBMIT_STATE,
      payload: true,
    });

    if (!selectedValue) {
      API.graphql(
        graphqlOperation(createIncreasedRegularlyWasteCollectionSchedule, {
          input: {
            id: rows.id,
            scheduleDate: DateTime.fromJSDate(data.date).toISODate(),
            scheduleTimeRangeStart: data.times.start + ":00",
            scheduleTimeRangeEnd: data.times.end + ":00",
            remarks: data.remarks,
          },
        })
      )
        .then((res) => {
          reduxDispatch(
            addAlert({
              value: "登録しました。",
              severity: "success",
            })
          );

          dispatch({
            type: ActionTypes.CLOSE_DIALOG,
          });
          loadSchedules();
          onChange();
        })
        .catch((err) => {
          debugLog("回収サイクルの登録に失敗: ", err);
          reduxDispatch(
            addAlert({
              value: "エラーが発生したため、登録できませんでした。",
              severity: "error",
            })
          );
        })
        .finally(() => {
          dispatch({
            type: ActionTypes.SET_SUBMIT_STATE,
            payload: false,
          });
        });
    } else {
      // UNDONE: 回収時間更新api待ち
      API.graphql(
        graphqlOperation("", {
          input: {
            id: rows.id,
            scheduleDate: DateTime.fromJSDate(data.date).toISODate(),
            scheduleTimeRangeStart: data.times.start + ":00",
            scheduleTimeRangeEnd: data.times.end + ":00",
            remarks: data.remarks,
          },
        })
      )
        .then((res) => {
          dispatch({
            type: ActionTypes.CLOSE_DIALOG,
          });
          reduxDispatch(
            addAlert({
              value: "エラーが発生したため、保存できませんでした。",
              severity: "error",
            })
          );
          loadSchedules();
          onChange();
        })
        .catch((err) => {
          debugLog("回収サイクル保存に失敗: ", err);
        })
        .finally(() => {
          dispatch({
            type: ActionTypes.SET_SUBMIT_STATE,
            payload: false,
          });
        });
    }
  };

  const handleIncrease = () => {
    dispatch({
      type: ActionTypes.OPEN_DIALOG,
    });
  };

  const handleClickChangeTimes = () => {
    dispatch({
      type: ActionTypes.OPEN_DIALOG,
    });
  };

  const handleClickDelete = () => {
    exeDelete(selectedValue);
  };

  const handleBulkReduce = () => {
    const promises = selectedRows.map((item) => {
      const schedule = rows.schedules[item];
      if (schedule.scheduleState === SCHEDULE_STATES.INCREASE) {
        return API.graphql(
          graphqlOperation(removeIncreasedRegularlyWasteCollectionSchedule, {
            id: schedule.scheduleId,
          })
        );
      }
      return API.graphql(
        graphqlOperation(reduceRegularlyWasteCollectionSchedule, {
          input: {
            id: schedule.cycleId,
            scheduleDate: DateTime.fromFormat(
              schedule.scheduleDate,
              "yyyy-MM-dd"
            ).toISODate(),
            remarks: schedule.remarks,
          },
        })
      );
    });

    Promise.all(promises)
      .then(() => {
        reduxDispatch(
          addAlert({
            value: "削除しました。",
            severity: "success",
          })
        );
        dispatch({
          type: ActionTypes.CLOSE_MENU,
        });
        setSelectedRows([]);
        setIsAllChecked(false);
        loadSchedules();
      })
      .catch((err) => {
        reduxDispatch(
          addAlert({
            value: "エラーが発生したため、削除できませんでした。",
            severity: "error",
          })
        );
      });
  };

  return render({
    rows: rows,
    month: current_month.toFormat("yyyy年MM月"),
    onClickLeft: handleClickLeft,
    onClickRight: handleClickRight,
    onClickThisMonth: handleThisMonth,
    onClickIncrease: handleIncrease,
    onClickChangeTimes: handleClickChangeTimes,
    onClickDelete: handleClickDelete,
    onOpenMenu: handleOpenMenu,
    onCloseMenu: handleCloseMenu,
    columns: columns,
    anchorEl: anchorEl,
    selectedValue: selectedValue,
    formRef: formRef,
    onConfirm: handleConfirm,
    onSubmit: handleSubmit,
    onCloseDialog: handleCloseDialog,
    open: open,
    isSubmit: isSubmit,
    selectedRows: selectedRows,
    handleBulkReduce: handleBulkReduce,
    ...props,
  });
};
