import React, { useState, useEffect } from "react";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import { makeStyles } from "@mui/styles";
import {
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AUTHSTATES, selector, set } from "ducks/Auth";

import { AuthenticationTemplate } from "../../organisms";
import { debugLog } from "utils/log";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const initialState = {
  username: "",
  code: "",
  password: "",
};

const _validAuthStates = [AUTHSTATES.FORGOT_PASSWORD];
export const ForgotPassword = (props) => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [delivery, setDelivery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const { authState } = useSelector(selector);
  const dispatch = useDispatch();

  const onStateChange = (state) => {
    dispatch(set(state));
  };

  useEffect(() => {
    setDelivery(null);
  }, [authState]);

  const setInput = (key, value) => setFormState({ ...formState, [key]: value });

  const send = () => {
    const { username } = formState;
    resetPassword({ username })
      .then((data) => {
        debugLog(data);
        setDelivery(data?.nextStep.codeDeliveryDetails);
      })
      .catch((err) => {
        debugLog(err);
        setError("エラーが発生しました。");
      });
  };

  const submit = () => {
    setError("");
    setLoading(true);
    const { username, code, password } = formState;
    confirmResetPassword({
      username,
      confirmationCode: code,
      newPassword: password,
    })
      .then((data) => {
        debugLog(data);
        onStateChange(AUTHSTATES.SIGN_IN);
        setDelivery(null);
      })
      .catch((err) => {
        debugLog("Full Error Object:", err);
        debugLog("Error Message:", err.message);
        debugLog("Error Name:", err.name);
        debugLog("Stack Trace:", err.stack);

        switch (err.name) {
          case "CodeMismatchException":
            setError("入力されたコードに誤りがあります。");
            break;
          case "LimitExceededException":
            setError("入力規定回数を超えました。");
            break;
          case "ExpiredCodeException":
            setError("入力されたコードの有効期限が切れています。");
            setDelivery(null);
            break;
          case "InvalidPasswordException":
          case "InvalidParameterException":
            setError("指定されたパスワードは規定の強度を満たしていません。");
            break;
          default:
            debugLog(err);
            setError("エラーが発生しました。");
            break;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    _validAuthStates.includes(authState) && (
      <AuthenticationTemplate>
        <Typography component="h2" variant="h5">
          {delivery
            ? "確認コードを入力して新しいパスワードを設定する"
            : "パスワード再設定メールを送る"}
        </Typography>
        <form className={classes.form} noValidate>
          {delivery ? (
            <>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="確認コード"
                autoFocus
                value={formState.code}
                onChange={(event) => setInput("code", event.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                value={formState.password}
                onChange={(event) => setInput("password", event.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                disabled={loading}
                onClick={submit}
                fullWidth
              >
                {loading ? <CircularProgress size={24} /> : "設定"}
              </Button>
            </>
          ) : (
            <>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email Address"
                autoFocus
                autoComplete="email"
                value={formState.username}
                onChange={(event) => setInput("username", event.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                onClick={send}
                fullWidth
              >
                送信
              </Button>
            </>
          )}
          {error && (
            <Typography color="error" paragraph>
              {error}
            </Typography>
          )}
          <Link
            variant="body2"
            component="button"
            onClick={() => onStateChange(AUTHSTATES.SIGN_IN)}
          >
            ログインに戻る
          </Link>
        </form>
      </AuthenticationTemplate>
    )
  );
};
