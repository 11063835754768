export const sampleData = [
  {
    id: 1,
    establishmentedOn: "2024/01/15",
    companyCategories: [
      { id: 1, name: "排出事業者" }
    ],
    name: "株式会社ABC",
  },
  {
    id: 2,
    establishmentedOn: "2024/02/10",
    companyCategories: [
      { id: 2, name: "収集運搬事業者" }
    ],
    name: "株式会社123",
  },
  {
    id: 3,
    establishmentedOn: "2024/03/05",
    companyCategories: [
      { id: 1, name: "排出事業者" },
      { id: 2, name: "収集運搬事業者" }
    ],
    name: "有限会社XYZ",
  },
  {
    id: 4,
    establishmentedOn: "2024/04/20",
    companyCategories: [
      { id: 1, name: "排出事業者" },
    ],
    name: "佐藤花子",
  },
  {
    id: 5,
    establishmentedOn: "2024/05/15",
    companyCategories: [
      { id: 2, name: "収集運搬事業者" }
    ],
    name: "株式会社123",
  },
];
