export const sampleDrivers = [
  { id: "D001", name: "佐藤 太郎" },
  { id: "D002", name: "鈴木 一郎" },
  { id: "D003", name: "高橋 花子" },
  { id: "D004", name: "田中 健" },
  { id: "D005", name: "山本 純子" },
];

export const status = {
  current: "CURRENT",
  next: "NEXT",
};

export const driverTaskData = {
  [sampleDrivers.at(0).id]: [
    {
      arrivedAt: new Date().toISOString(),
      departedAt: null,
      id: "01JAJR4K3QABF8XJ8V2ABP1GFJ",
      order: 1,
      wasteInKg: 100,
      status: "current",
      workplace: {
        id: "01G4VY464NB3DT5HG6TS46H96Z",
        name: "ABC株式会社",
        phoneticGuidesOfName: "",
        postalCode: "123-4567",
        prefecture: "東京都",
        city: "千代田区",
        streetAddress: "千代田1-1-1",
        otherAddress: "",
        prefectures: { code: "13", name: "東京都", removedAt: null },
      },
    },
    {
      arrivedAt: new Date().toISOString(),
      departedAt: null,
      id: "01JAJR4K3QABF8XJ8V2ABP1GFJ",
      order: 2,
      wasteInKg: 50,
      status: "next",
      workplace: {
        id: "01G4VY464NB3DT5HG6TS46H96Z",
        name: "XYZ有限会社",
        phoneticGuidesOfName: "",
        postalCode: "234-5678",
        prefecture: "神奈川県",
        city: "横浜市",
        streetAddress: "横浜1-1-1",
        otherAddress: "",
        prefectures: { code: "14", name: "神奈川県", removedAt: null },
      },
    }
  ],
  [sampleDrivers.at(1).id]: [
    {
      arrivedAt: new Date().toISOString(),
      departedAt: null,
      id: "01JAJR4K3QABF8XJ8V2ABP1GFJ",
      order: 3,
      wasteInKg: 200,
      status: "not_started",
      workplace: {
        id: "01G4VY464NB3DT5HG6TS46H96Z",
        name: "DEF産業",
        phoneticGuidesOfName: "",
        postalCode: "345-6789",
        prefecture: "埼玉県",
        city: "さいたま市",
        streetAddress: "さいたま1-1-1",
        otherAddress: "",
        prefectures: { code: "11", name: "埼玉県", removedAt: null },
      },
    },
    {
      arrivedAt: new Date().toISOString(),
      departedAt: null,
      id: "01JAJR4K3QABF8XJ8V2ABP1GFJ",
      order: 4,
      wasteInKg: 75,
      status: "next",
      workplace: {
        id: "01G4VY464NB3DT5HG6TS46H96Z",
        name: "GHI商会",
        phoneticGuidesOfName: "",
        postalCode: "456-7890",
        prefecture: "千葉県",
        city: "千葉市",
        streetAddress: "千葉1-1-1",
        otherAddress: "",
        prefectures: { code: "12", name: "千葉県", removedAt: null },
      },
    }
  ],
  [sampleDrivers.at(2).id]: [
    {
      arrivedAt: new Date().toISOString(),
      departedAt: null,
      id: "01JAJR4K3QABF8XJ8V2ABP1GFJ",
      order: 5,
      wasteInKg: 250,
      status:"current",
      workplace: {
        id: "01G4VY464NB3DT5HG6TS46H96Z",
        name: "JKL株式会社",
        phoneticGuidesOfName: "",
        postalCode: "456-7890",
        prefecture: "千葉県",
        city: "千葉市",
        streetAddress: "千葉1-1-1",
        otherAddress: "",
        prefectures: { code: "12", name: "千葉県", removedAt: null },
      },
    },
    {
      arrivedAt: new Date().toISOString(),
      departedAt: null,
      id: "01JAJR4K3QABF8XJ8V2ABP1GFJ",
      order: 6,
      wasteInKg: 150,
      status: "next",
      workplace: {
        id: "01G4VY464NB3DT5HG6TS46H96Z",
        name: "MNO株式会社",
        phoneticGuidesOfName: "",
        postalCode: "567-8901",
        prefecture: "大阪府",
        city: "大阪市",
        streetAddress: "大阪1-1-1",
        otherAddress: "",
        prefectures: { code: "27", name: "大阪府", removedAt: null },
      },
    }
  ],
  [sampleDrivers.at(3).id]: [
    {
      arrivedAt: new Date().toISOString(),
      departedAt: null,
      id: "01JAJR4K3QABF8XJ8V2ABP1GFJ",
      order: 7,
      wasteInKg: 180,
      status: "current",
      workplace: {
        id: "01G4VY464NB3DT5HG6TS46H96Z",
        name: "Ecope株式会社",
        phoneticGuidesOfName: "",
        postalCode: "678-9012",
        prefecture: "愛知県",
        city: "名古屋市",
        streetAddress: "名古屋1-1-1",
        otherAddress: "",
        prefectures: { code: "23", name: "愛知県", removedAt: null },
      },
    },
    {
      arrivedAt: new Date().toISOString(),
      departedAt: null,
      id: "01JAJR4K3QABF8XJ8V2ABP1GFJ",
      order: 8,
      wasteInKg: 220,
      status: "next",
      workplace: {
        id: "01G4VY464NB3DT5HG6TS46H96Z",
        name: "Greener株式会社",
        phoneticGuidesOfName: "",
        postalCode: "789-0123",
        prefecture: "京都府",
        city: "京都市",
        streetAddress: "京都1-1-1",
        otherAddress: "",
        prefectures: { code: "26", name: "京都府", removedAt: null },
      },
    }
  ],
  [sampleDrivers.at(4).id]: [],
};

