import { getGridNumericOperators } from "@mui/x-data-grid-pro";
import { createVehicle } from "api/graphql/mutations";
import { searchVehiclesByOffset } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { DateTime } from "luxon";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { debugLog } from "utils/log";
import { useXGridComponents } from "utils/useXGridComponents";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

export const columns = [
  {
    field: "number",
    headerName: "車輛ナンバー",
    minWidth: 170,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "modelName",
    headerName: "車種",
    minWidth: 160,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "parkingCertificateOwnerWorkplaceName",
    headerName: "保管場所",
    flex: 1,
    minWidth: 180,
    valueGetter: (params) =>
      params.row?.parkingCertificateOwnerWorkplace?.name ?? "",
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "loadCapacity",
    type: "number",
    headerName: "最大積載量",
    minWidth: 160,
    flex: 1,
    renderCell: ({ value }) => `${value} kg`,
    filterOperators: getGridNumericOperators().filter(
      (operator) =>
        operator.value === ">" ||
        operator.value === ">=" ||
        operator.value === "<" ||
        operator.value === "<=" ||
        operator.value === "=" ||
        operator.value === "!="
    ),
  },
  {
    field: "totalWeight",
    headerName: "車輛総重量",
    type: "number",
    minWidth: 160,
    flex: 1,
    renderCell: ({ value }) => `${value} kg`,
    filterOperators: getGridNumericOperators().filter(
      (operator) =>
        operator.value === ">" ||
        operator.value === ">=" ||
        operator.value === "<" ||
        operator.value === "<=" ||
        operator.value === "=" ||
        operator.value === "!="
    ),
  },
  {
    field: "ridingCapacity",
    headerName: "乗車定員",
    type: "number",
    hide: true,
    minWidth: 135,
    flex: 1,
    filterOperators: getGridNumericOperators().filter(
      (operator) =>
        operator.value === ">" ||
        operator.value === ">=" ||
        operator.value === "<" ||
        operator.value === "<=" ||
        operator.value === "=" ||
        operator.value === "!="
    ),
  },
  {
    field: "purchasedOn",
    headerName: "車輛購入年月日",
    hide: true,
    minWidth: 180,
    flex: 1,
    valueFormatter: (params) => {
      return params.value
        ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
        : "";
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "firstYearRegistrationOn",
    headerName: "車輛初年度登録日",
    hide: true,
    minWidth: 190,
    flex: 1,
    valueFormatter: (params) => {
      return params.value
        ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
        : "";
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "remarks",
    headerName: "備考",
    hide: true,
    width: 200,
    flex: 1,
    valueFormatter: (params) => {
      return params.value ? params.value?.split("\n").join(" ") : "";
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
];

/**
 * 車輛選択するコンテナコンポーネントです。
 * @returns
 */
export const Container = ({
  render,
  value,
  onSelected = () => {},
  title = "",
  multiple = false,
  checkbox = false,
  companyId,
  action = true,
  initialState,
  autoHeight = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const xGridObject = useXGridComponents(
    columns,
    searchVehiclesByOffset,
    {
      other: {
        ownerCompanyId: companyId,
      },
    },
    initialState
  );

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleSelected = (items) => {
    onSelected({
      value: xGridObject.params.rows.filter((row) => items.includes(row.id)),
      info: {
        search: xGridObject.search,
      },
    });
  };

  const handleSubmit = (data) => {
    const {
      number,
      parkingCertificateOwnerWorkplace,
      modelName,
      // driversLicense,
      purchasedOn,
      firstYearRegistrationOn,
      loadCapacity,
      totalWeight,
      ridingCapacity,
      remarks,
      // ...other
    } = data;
    setIsSubmit(true);
    API.graphql(
      graphqlOperation(createVehicle, {
        input: {
          number: number,
          modelName: modelName,
          parkingCertificateOwnerWorkplaceId:
            parkingCertificateOwnerWorkplace?.id ?? null,
          purchasedOn:
            purchasedOn &&
            DateTime.fromJSDate(new Date(purchasedOn)).toFormat("yyyy-MM-dd"),
          firstYearRegistrationOn:
            firstYearRegistrationOn &&
            DateTime.fromJSDate(new Date(firstYearRegistrationOn)).toFormat(
              "yyyy-MM-dd"
            ),
          ownerCompanyId: companyId,
          loadCapacity: loadCapacity,
          totalWeight: totalWeight,
          ridingCapacity: ridingCapacity,
          remarks: remarks,
        },
      })
    )
      .then((result) => {
        debugLog("vehicle.onCreate.succeeded", result);
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        xGridObject.functions.refetch();
        handleCloseDialog();
      })
      .catch((error) => {
        debugLog("vehicle.onCreate.failed", error);
        const errorType = error?.errors[0]?.errorType;

        if (errorType === "vehicle_already_exists") {
          dispatch(
            addAlert({
              value: "車輛ナンバーが重複しているため、登録できませんでした。",
              severity: "error",
            })
          );
        } else {
          dispatch(
            addAlert({
              value: "エラーが発生したため、登録できませんでした。",
              severity: "error",
            })
          );
        }
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return render({
    open: open,
    value: value,
    title: title,
    multiple: multiple,
    isSubmit: isSubmit,
    xGridParams: xGridObject.params,
    onCloseDialog: handleCloseDialog,
    onOpenDialog: handleOpenDialog,
    onSelected: handleSelected,
    onSubmit: handleSubmit,
    checkbox: checkbox,
    action: action,
    companyId: companyId,
    autoHeight: autoHeight,
    ...props,
  });
};
