/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContainer = /* GraphQL */ `
  query GetContainer($id: ID!) {
    getContainer(id: $id) {
      id
      product {
        id
        number
        name
        manufacturerName
        modelNumber
        width
        height
        depth
        volume
        remarks
        state
        ownerCompanyId
        files {
          name
          url
          order
          label
        }
        version
      }
      additionalType {
        id
        order
        name
        removedAt
      }
      addedOn
      manageWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      installationLocationCategory
      installationWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      installedOn
      number
      remarks
      state
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const getContainerProduct = /* GraphQL */ `
  query GetContainerProduct($id: ID!) {
    getContainerProduct(id: $id) {
      id
      number
      name
      manufacturerName
      modelNumber
      width
      height
      depth
      volume
      remarks
      state
      ownerCompanyId
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const listContainerAdditionalTypes = /* GraphQL */ `
  query ListContainerAdditionalTypes($sortDirection: ModelSortDirection) {
    listContainerAdditionalTypes(sortDirection: $sortDirection) {
      items {
        id
        order
        name
        removedAt
      }
    }
  }
`;
export const listContainerDisuseTypes = /* GraphQL */ `
  query ListContainerDisuseTypes($sortDirection: ModelSortDirection) {
    listContainerDisuseTypes(sortDirection: $sortDirection) {
      items {
        id
        order
        name
        removedAt
      }
    }
  }
`;
export const listContainerManufacturers = /* GraphQL */ `
  query ListContainerManufacturers($sortDirection: ModelSortDirection) {
    listContainerManufacturers(sortDirection: $sortDirection) {
      items {
        name
      }
    }
  }
`;
export const listContainerMovementLogsByOffset = /* GraphQL */ `
  query ListContainerMovementLogsByOffset(
    $containerId: ID!
    $sortDirection: ModelSortDirection
    $offset: Int
    $limit: Int
  ) {
    listContainerMovementLogsByOffset(
      containerId: $containerId
      sortDirection: $sortDirection
      offset: $offset
      limit: $limit
    ) {
      items {
        logId
        containerId
        beforeLocation {
          category
          workplace {
            id
            name
            phoneticGuidesOfName
            postalCode
            prefectures {
              code
              name
              removedAt
            }
            city
            streetAddress
            otherAddress
            email
            phone
            fax
            position {
              lat
              lng
            }
            belongInCompanyId
            belongInCompanyName
            precautions {
              id
              name
              message
              fileCount
              ownerCompanyId
              lastUpdatedOn
            }
          }
          installedOn
        }
        afterLocation {
          category
          workplace {
            id
            name
            phoneticGuidesOfName
            postalCode
            prefectures {
              code
              name
              removedAt
            }
            city
            streetAddress
            otherAddress
            email
            phone
            fax
            position {
              lat
              lng
            }
            belongInCompanyId
            belongInCompanyName
            precautions {
              id
              name
              message
              fileCount
              ownerCompanyId
              lastUpdatedOn
            }
          }
          installedOn
        }
        movedOn
        vehicle {
          id
          number
          modelName
        }
        personInChargeName
        remarks
      }
      total
    }
  }
`;
export const searchContainerProductsByOffset = /* GraphQL */ `
  query SearchContainerProductsByOffset(
    $ownerCompanyId: ID
    $filter: SearchableContainerProductFilterInput
    $sort: SearchableContainerProductSortInput
    $offset: Int
    $limit: Int
  ) {
    searchContainerProductsByOffset(
      ownerCompanyId: $ownerCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        number
        name
        manufacturerName
        modelNumber
        width
        height
        depth
        volume
        remarks
        totalOfOwned
        numberOfActives
        numberOfDisuse
        numberOfStorage
        numberOfInstallations
        state
        ownerCompanyId
      }
      nextToken
      total
    }
  }
`;
export const searchContainersByOffset = /* GraphQL */ `
  query SearchContainersByOffset(
    $ownerCompanyId: ID
    $filter: SearchableContainerFilterInput
    $sort: SearchableContainerSortInput
    $offset: Int
    $limit: Int
  ) {
    searchContainersByOffset(
      ownerCompanyId: $ownerCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        number
        productId
        productNumber
        productName
        productManufacturerName
        productModelNumber
        manageWorkplaceId
        manageWorkplaceName
        installationWorkplaceId
        installationLocationCategory
        installationCompanyName
        installationWorkplaceName
        installedOn
        daysElapsedAfterInstallation
        latestConfirmerName
        latestConfirmedOn
        state
      }
      nextToken
      total
    }
  }
`;
export const getAggregationWasteCollectionRecord = /* GraphQL */ `
  query GetAggregationWasteCollectionRecord(
    $aggregateOn: DateRange!
    $mode: AggregationMode!
    $dataset: AggregationWasteCollectionRecordDataset!
    $dateType: AggregationWasteCollectionRecordDateType!
    $method: AggregationMethod!
    $groupColumns: AggregationWasteCollectionRecordViewGroupColumn!
    $offset: Int
    $limit: Int
  ) {
    getAggregationWasteCollectionRecord(
      aggregateOn: $aggregateOn
      mode: $mode
      dataset: $dataset
      dateType: $dateType
      method: $method
      groupColumns: $groupColumns
      offset: $offset
      limit: $limit
    ) {
      items {
        groupColumns {
          key
          value
        }
        aggregationColumns {
          key
          value
        }
      }
      total
    }
  }
`;
export const getCarryWorkJournal = /* GraphQL */ `
  query GetCarryWorkJournal($id: ID!) {
    getCarryWorkJournal(id: $id) {
      id
      name
      departureDate
      departureTime
      assignedVehicle {
        id
        number
        modelOfVehicleName
      }
      assignedUsers {
        id
        name
      }
      instruments {
        type
        odometer
        updatedAt
      }
      items {
        id
        workplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
          belongInCompanyId
          belongInCompanyName
          precautions {
            id
            name
            message
            fileCount
            ownerCompanyId
            lastUpdatedOn
          }
        }
        tasks {
          id
          waste {
            id
            number
            type {
              id
              largeClass {
                code
                name
                removedAt
              }
              middleClass {
                code
                name
                removedAt
              }
              smallClass {
                code
                name
                removedAt
              }
              group {
                id
                name
                removedAt
              }
              removedAt
            }
            kind
            name
            packing {
              code
              name
              removedAt
            }
            packingDetails
            quantityUnit {
              code
              name
              removedAt
            }
            weightConvertedValue
            weightConvertedValueUnit {
              id
              order
              name
              removedAt
            }
            dischargeFrequency {
              interval {
                id
                order
                name
                removedAt
              }
              numberOfTimes
            }
            dischargeAmountPerOneTime
            isImportedWaste
            state
            dischargeSourceWorkplaceId
            version
          }
          wasteQuantity
          scheduleDate
          scheduleTimeRangeStart
          scheduleTimeRangeEnd
          record {
            wasteFlowId
            quantity
            quantityUnit {
              code
              name
              removedAt
            }
            weightOfKg
            remarks
            createdAt
            updatedAt
            version
          }
          remarks
          createdAt
          updatedAt
          version
        }
        passedRecord {
          arrivedAt
          departedAt
          version
        }
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const getCarryWorkJournalLocationsByDriver = /* GraphQL */ `
  query GetCarryWorkJournalLocationsByDriver($driverId: ID!, $date: AWSDate!) {
    getCarryWorkJournalLocationsByDriver(driverId: $driverId, date: $date) {
      current {
        id
        order
        workplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
          belongInCompanyId
          belongInCompanyName
          precautions {
            id
            name
            message
            fileCount
            ownerCompanyId
            lastUpdatedOn
          }
        }
        wasteInKg
        status
        departedAt
        arrivedAt
      }
      next {
        id
        order
        workplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
          belongInCompanyId
          belongInCompanyName
          precautions {
            id
            name
            message
            fileCount
            ownerCompanyId
            lastUpdatedOn
          }
        }
        wasteInKg
        status
        departedAt
        arrivedAt
      }
    }
  }
`;
export const getCourseAutomationSetting = /* GraphQL */ `
  query GetCourseAutomationSetting {
    getCourseAutomationSetting {
      id
      courses {
        id
        name
        departureTime
        assignedVehicle {
          id
          number
          modelOfVehicleName
        }
        assignedUsers {
          id
          name
        }
        pointTotal
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const getIndividualityWasteCollectionOrder = /* GraphQL */ `
  query GetIndividualityWasteCollectionOrder($id: ID!) {
    getIndividualityWasteCollectionOrder(id: $id) {
      id
      wasteGeneratorCompany {
        id
        name
        phoneticGuidesOfName
      }
      wasteCollectionWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      wasteCarrierCompany {
        id
        name
        phoneticGuidesOfName
      }
      scheduleDate
      scheduleTimeRangeStart
      scheduleTimeRangeEnd
      remarks
      wastes {
        id
        waste {
          id
          number
          type {
            id
            largeClass {
              code
              name
              removedAt
            }
            middleClass {
              code
              name
              removedAt
            }
            smallClass {
              code
              name
              removedAt
            }
            group {
              id
              name
              removedAt
            }
            removedAt
          }
          kind
          name
          packing {
            code
            name
            removedAt
          }
          packingDetails
          quantityUnit {
            code
            name
            removedAt
          }
          weightConvertedValue
          weightConvertedValueUnit {
            id
            order
            name
            removedAt
          }
          dischargeFrequency {
            interval {
              id
              order
              name
              removedAt
            }
            numberOfTimes
          }
          dischargeAmountPerOneTime
          isImportedWaste
          state
          dischargeSourceWorkplaceId
          version
        }
        quantity
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const getLogisticsWorkAllocation = /* GraphQL */ `
  query GetLogisticsWorkAllocation($date: AWSDate!) {
    getLogisticsWorkAllocation(date: $date) {
      id
      date
      courses {
        id
        name
        departureDate
        departureTime
        assignedVehicle {
          id
          number
          modelOfVehicleName
        }
        assignedUsers {
          id
          name
        }
        points {
          id
          workplace {
            id
            name
            phoneticGuidesOfName
            postalCode
            prefectures {
              code
              name
              removedAt
            }
            city
            streetAddress
            otherAddress
            email
            phone
            fax
            position {
              lat
              lng
            }
          }
          assignedWasteCollectionSchedules {
            id
            wasteGeneratorCompany {
              id
              name
              phoneticGuidesOfName
            }
            wasteCollectionWorkplace {
              id
              name
              phoneticGuidesOfName
              postalCode
              prefectures {
                code
                name
                removedAt
              }
              city
              streetAddress
              otherAddress
              email
              phone
              fax
              position {
                lat
                lng
              }
            }
            wasteCarrierCompany {
              id
              name
              phoneticGuidesOfName
            }
            waste {
              id
              number
              type {
                id
                largeClass {
                  code
                  name
                  removedAt
                }
                middleClass {
                  code
                  name
                  removedAt
                }
                smallClass {
                  code
                  name
                  removedAt
                }
                group {
                  id
                  name
                  removedAt
                }
                removedAt
              }
              kind
              name
              packing {
                code
                name
                removedAt
              }
              packingDetails
              quantityUnit {
                code
                name
                removedAt
              }
              weightConvertedValue
              weightConvertedValueUnit {
                id
                order
                name
                removedAt
              }
              dischargeFrequency {
                interval {
                  id
                  order
                  name
                  removedAt
                }
                numberOfTimes
              }
              dischargeAmountPerOneTime
              isImportedWaste
              state
              dischargeSourceWorkplaceId
              version
            }
            wasteQuantity
            scheduleDate
            scheduleTimeRangeStart
            scheduleTimeRangeEnd
            createdAt
            updatedAt
            version
          }
          createdAt
          updatedAt
          version
        }
        pointTotal
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const getLogisticsWorkAllocationCourses = /* GraphQL */ `
  query GetLogisticsWorkAllocationCourses($date: AWSDate!) {
    getLogisticsWorkAllocationCourses(date: $date) {
      id
      date
      courses {
        id
        name
        departureDate
        departureTime
        assignedVehicle {
          id
          number
          modelOfVehicleName
        }
        assignedUsers {
          id
          name
        }
        points {
          id
          workplace {
            id
            name
            phoneticGuidesOfName
            postalCode
            prefectures {
              code
              name
              removedAt
            }
            city
            streetAddress
            otherAddress
            email
            phone
            fax
            position {
              lat
              lng
            }
          }
          assignedWasteCollectionSchedules {
            id
            wasteGeneratorCompany {
              id
              name
              phoneticGuidesOfName
            }
            wasteCollectionWorkplace {
              id
              name
              phoneticGuidesOfName
              postalCode
              prefectures {
                code
                name
                removedAt
              }
              city
              streetAddress
              otherAddress
              email
              phone
              fax
              position {
                lat
                lng
              }
            }
            wasteCarrierCompany {
              id
              name
              phoneticGuidesOfName
            }
            waste {
              id
              number
              type {
                id
                largeClass {
                  code
                  name
                  removedAt
                }
                middleClass {
                  code
                  name
                  removedAt
                }
                smallClass {
                  code
                  name
                  removedAt
                }
                group {
                  id
                  name
                  removedAt
                }
                removedAt
              }
              kind
              name
              packing {
                code
                name
                removedAt
              }
              packingDetails
              quantityUnit {
                code
                name
                removedAt
              }
              weightConvertedValue
              weightConvertedValueUnit {
                id
                order
                name
                removedAt
              }
              dischargeFrequency {
                interval {
                  id
                  order
                  name
                  removedAt
                }
                numberOfTimes
              }
              dischargeAmountPerOneTime
              isImportedWaste
              state
              dischargeSourceWorkplaceId
              version
            }
            wasteQuantity
            scheduleDate
            scheduleTimeRangeStart
            scheduleTimeRangeEnd
            createdAt
            updatedAt
            version
          }
          createdAt
          updatedAt
          version
        }
        pointTotal
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const getLogisticsWorkAllocationPointsByCourse = /* GraphQL */ `
  query GetLogisticsWorkAllocationPointsByCourse($id: ID!) {
    getLogisticsWorkAllocationPointsByCourse(id: $id) {
      id
      points {
        id
        workplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        assignedWasteCollectionSchedules {
          id
          wasteGeneratorCompany {
            id
            name
            phoneticGuidesOfName
          }
          wasteCollectionWorkplace {
            id
            name
            phoneticGuidesOfName
            postalCode
            prefectures {
              code
              name
              removedAt
            }
            city
            streetAddress
            otherAddress
            email
            phone
            fax
            position {
              lat
              lng
            }
          }
          wasteCarrierCompany {
            id
            name
            phoneticGuidesOfName
          }
          waste {
            id
            number
            type {
              id
              largeClass {
                code
                name
                removedAt
              }
              middleClass {
                code
                name
                removedAt
              }
              smallClass {
                code
                name
                removedAt
              }
              group {
                id
                name
                removedAt
              }
              removedAt
            }
            kind
            name
            packing {
              code
              name
              removedAt
            }
            packingDetails
            quantityUnit {
              code
              name
              removedAt
            }
            weightConvertedValue
            weightConvertedValueUnit {
              id
              order
              name
              removedAt
            }
            dischargeFrequency {
              interval {
                id
                order
                name
                removedAt
              }
              numberOfTimes
            }
            dischargeAmountPerOneTime
            isImportedWaste
            state
            dischargeSourceWorkplaceId
            version
          }
          wasteQuantity
          scheduleDate
          scheduleTimeRangeStart
          scheduleTimeRangeEnd
          createdAt
          updatedAt
          version
        }
        createdAt
        updatedAt
        version
      }
    }
  }
`;
export const getQueingTasks = /* GraphQL */ `
  query GetQueingTasks($companyId: ID!) {
    getQueingTasks(companyId: $companyId)
  }
`;
export const getRegularlyWasteCollectionOrder = /* GraphQL */ `
  query GetRegularlyWasteCollectionOrder($id: ID!) {
    getRegularlyWasteCollectionOrder(id: $id) {
      id
      wasteGeneratorCompany {
        id
        name
        phoneticGuidesOfName
      }
      wasteCollectionWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      wasteCarrierCompany {
        id
        name
        phoneticGuidesOfName
      }
      waste {
        id
        number
        type {
          id
          largeClass {
            code
            name
            removedAt
          }
          middleClass {
            code
            name
            removedAt
          }
          smallClass {
            code
            name
            removedAt
          }
          group {
            id
            name
            removedAt
          }
          removedAt
        }
        kind
        name
        packing {
          code
          name
          removedAt
        }
        packingDetails
        quantityUnit {
          code
          name
          removedAt
        }
        weightConvertedValue
        weightConvertedValueUnit {
          id
          order
          name
          removedAt
        }
        dischargeFrequency {
          interval {
            id
            order
            name
            removedAt
          }
          numberOfTimes
        }
        dischargeAmountPerOneTime
        isImportedWaste
        state
        dischargeSourceWorkplaceId
        version
      }
      remarks
      cycles {
        id
        cycleCategoryId
        cycleCategoryName
        cycleStartDate
        cycleEndDate
        scheduleTimeRangeStart
        scheduleTimeRangeEnd
        remarks
        months
        weeks
        intervalDays
        weekDays
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const getRegularlyWasteCollectionSchedules = /* GraphQL */ `
  query GetRegularlyWasteCollectionSchedules(
    $id: ID!
    $year: Int!
    $month: Int!
  ) {
    getRegularlyWasteCollectionSchedules(id: $id, year: $year, month: $month) {
      id
      year
      month
      schedules {
        cycleId
        cycleDate
        scheduleId
        scheduleState
        scheduleDate
        scheduleTimeRangeStart
        scheduleTimeRangeEnd
        remarks
      }
    }
  }
`;
export const listCycleCategories = /* GraphQL */ `
  query ListCycleCategories($sortDirection: ModelSortDirection) {
    listCycleCategories(sortDirection: $sortDirection) {
      items {
        id
        order
        name
        removedAt
      }
    }
  }
`;
export const listVirtualCoursePointsByOffset = /* GraphQL */ `
  query ListVirtualCoursePointsByOffset(
    $virtualCourseId: ID!
    $offset: Int
    $limit: Int
  ) {
    listVirtualCoursePointsByOffset(
      virtualCourseId: $virtualCourseId
      offset: $offset
      limit: $limit
    ) {
      id
      workplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      assignedRegularlyWasteCollectionCycles {
        acceptingOrderId
        wasteGeneratorCompany {
          id
          name
          phoneticGuidesOfName
        }
        wasteCollectionWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        wasteCarrierCompany {
          id
          name
          phoneticGuidesOfName
        }
        waste {
          id
          number
          type {
            id
            largeClass {
              code
              name
              removedAt
            }
            middleClass {
              code
              name
              removedAt
            }
            smallClass {
              code
              name
              removedAt
            }
            group {
              id
              name
              removedAt
            }
            removedAt
          }
          kind
          name
          packing {
            code
            name
            removedAt
          }
          packingDetails
          quantityUnit {
            code
            name
            removedAt
          }
          weightConvertedValue
          weightConvertedValueUnit {
            id
            order
            name
            removedAt
          }
          dischargeFrequency {
            interval {
              id
              order
              name
              removedAt
            }
            numberOfTimes
          }
          dischargeAmountPerOneTime
          isImportedWaste
          state
          dischargeSourceWorkplaceId
          version
        }
        cycleId
        cycleCategoryId
        cycleCategoryName
        cycleStartDate
        cycleEndDate
        scheduleTimeRangeStart
        scheduleTimeRangeEnd
        months
        weeks
        weekDays
        intervalDays
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const searchCarryWorkJournalsByOffset = /* GraphQL */ `
  query SearchCarryWorkJournalsByOffset(
    $filter: SearchableCarryWorkJournalFilterInput
    $sort: SearchableCarryWorkJournalSortInput
    $offset: Int
    $limit: Int
  ) {
    searchCarryWorkJournalsByOffset(
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        name
        departureDate
        departureTime
        assignedVehicle {
          id
          number
          modelOfVehicleName
        }
        assignedUsers {
          id
          name
        }
        taskTotal
        taskDoneCount
        taskSkippedCount
        pointTotal
        createdAt
        updatedAt
        notes {
          remark
          index
          workplace {
            id
            name
            phoneticGuidesOfName
            postalCode
            prefectures {
              code
              name
              removedAt
            }
            city
            streetAddress
            otherAddress
            email
            phone
            fax
            position {
              lat
              lng
            }
            belongInCompanyId
            belongInCompanyName
            precautions {
              id
              name
              message
              fileCount
              ownerCompanyId
              lastUpdatedOn
            }
          }
        }
        version
      }
      nextToken
      total
    }
  }
`;
export const searchIndividualityWasteCollectionOrdersByOffset = /* GraphQL */ `
  query SearchIndividualityWasteCollectionOrdersByOffset(
    $filter: SearchableIndividualityWasteCollectionOrderFilterInput
    $sort: SearchableIndividualityWasteCollectionOrderSortInput
    $offset: Int
    $limit: Int
  ) {
    searchIndividualityWasteCollectionOrdersByOffset(
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        acceptingOrderId
        collectionWasteId
        wasteGeneratorCompanyName
        wasteCollectionWorkplaceName
        scheduleDate
        scheduleTimeRangeStart
        scheduleTimeRangeEnd
        wasteLargeClassName
        wasteMiddleClassName
        wasteSmallClassName
        wasteName
        wasteQuantityValue
        wasteQuantityUnitName
        remarks
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchRegularlyWasteCollectionsOrdersByOffset = /* GraphQL */ `
  query SearchRegularlyWasteCollectionsOrdersByOffset(
    $filter: SearchableRegularlyWasteCollectionOrderFilterInput
    $sort: SearchableRegularlyWasteCollectionOrderSortInput
    $offset: Int
    $limit: Int
    $searchQuery: String
  ) {
    searchRegularlyWasteCollectionsOrdersByOffset(
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
      searchQuery: $searchQuery
    ) {
      items {
        acceptingOrderId
        wasteGeneratorCompany {
          id
          name
          phoneticGuidesOfName
        }
        wasteCollectionWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        wasteCarrierCompany {
          id
          name
          phoneticGuidesOfName
        }
        waste {
          id
          number
          type {
            id
            largeClass {
              code
              name
              removedAt
            }
            middleClass {
              code
              name
              removedAt
            }
            smallClass {
              code
              name
              removedAt
            }
            group {
              id
              name
              removedAt
            }
            removedAt
          }
          kind
          name
          packing {
            code
            name
            removedAt
          }
          packingDetails
          quantityUnit {
            code
            name
            removedAt
          }
          weightConvertedValue
          weightConvertedValueUnit {
            id
            order
            name
            removedAt
          }
          dischargeFrequency {
            interval {
              id
              order
              name
              removedAt
            }
            numberOfTimes
          }
          dischargeAmountPerOneTime
          isImportedWaste
          state
          dischargeSourceWorkplaceId
          version
        }
        cycleId
        cycleCategoryId
        cycleCategoryName
        cycleStartDate
        cycleEndDate
        scheduleTimeRangeStart
        scheduleTimeRangeEnd
        months
        weeks
        weekDays
        intervalDays
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getChecksheet = /* GraphQL */ `
  query GetChecksheet($id: ID!) {
    getChecksheet(id: $id) {
      id
      name
      description
      personInChargeName
      checkedOn
      sections {
        order
        name
        items {
          order
          name
          checked
        }
      }
      files {
        name
        url
        order
        label
      }
      remarks
      state
      version
    }
  }
`;
export const getChecksheetTemplate = /* GraphQL */ `
  query GetChecksheetTemplate($id: ID!) {
    getChecksheetTemplate(id: $id) {
      id
      category {
        id
        name
        removedAt
      }
      name
      description
      remarks
      state
      sections {
        order
        name
        items {
          order
          name
        }
      }
      version
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      name
      phoneticGuidesOfName
      categories {
        id
        order
        name
        removedAt
      }
      corporateNumber
      representativeName
      establishmentedOn
      url
      remarks
      state
      headOfficeWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      companyId
      profileVersion
    }
  }
`;
export const getCollectionPrecaution = /* GraphQL */ `
  query GetCollectionPrecaution($id: ID!) {
    getCollectionPrecaution(id: $id) {
      id
      name
      tags
      message
      ownerCompanyId
      ownerWorkplaceId
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID) {
    getCompany(id: $id) {
      id
      name
      phoneticGuidesOfName
      categories {
        id
        order
        name
        removedAt
      }
      corporateNumber
      representativeName
      establishmentedOn
      url
      headOfficeWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      profileVersion
    }
  }
`;
export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      number
      name
      type {
        id
        order
        name
        removedAt
      }
      executedOn
      termOfAgreementStartedOn
      termOfAgreementExpirationOn
      isAutomaticallyRenewed
      remarks
      state
      ownerCompanyId
      partyCompanies {
        id
        name
        phoneticGuidesOfName
      }
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const getWorkplace = /* GraphQL */ `
  query GetWorkplace($id: ID!) {
    getWorkplace(id: $id) {
      id
      name
      phoneticGuidesOfName
      categories {
        id
        order
        name
        removedAt
      }
      code
      industrySector {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        subClass {
          code
          name
          removedAt
        }
        removedAt
      }
      postalCode
      prefectures {
        code
        name
        removedAt
      }
      city
      streetAddress
      otherAddress
      email
      phone
      fax
      position {
        lat
        lng
      }
      remarks
      state
      belongInCompanyId
      profileVersion
      addressVersion
      contactDetailsVersion
      isMapInput
    }
  }
`;
export const listChecksheetCategories = /* GraphQL */ `
  query ListChecksheetCategories($sortDirection: ModelSortDirection) {
    listChecksheetCategories(sortDirection: $sortDirection) {
      items {
        id
        name
        removedAt
      }
    }
  }
`;
export const listCompanyCategories = /* GraphQL */ `
  query ListCompanyCategories($sortDirection: ModelSortDirection) {
    listCompanyCategories(sortDirection: $sortDirection) {
      items {
        id
        order
        name
        removedAt
      }
    }
  }
`;
export const listContractTypes = /* GraphQL */ `
  query ListContractTypes($sortDirection: ModelSortDirection) {
    listContractTypes(sortDirection: $sortDirection) {
      items {
        id
        order
        name
        removedAt
      }
    }
  }
`;
export const listIndustrySectorMiddleClasses = /* GraphQL */ `
  query ListIndustrySectorMiddleClasses($sortDirection: ModelSortDirection) {
    listIndustrySectorMiddleClasses(sortDirection: $sortDirection) {
      items {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        subClass {
          code
          name
          removedAt
        }
        removedAt
      }
    }
  }
`;
export const listIndustrySectors = /* GraphQL */ `
  query ListIndustrySectors($sortDirection: ModelSortDirection) {
    listIndustrySectors(sortDirection: $sortDirection) {
      items {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        subClass {
          code
          name
          removedAt
        }
        removedAt
      }
    }
  }
`;
export const listPrefectures = /* GraphQL */ `
  query ListPrefectures($sortDirection: ModelSortDirection) {
    listPrefectures(sortDirection: $sortDirection) {
      items {
        code
        name
        removedAt
      }
    }
  }
`;
export const listWorkplaceCategories = /* GraphQL */ `
  query ListWorkplaceCategories($sortDirection: ModelSortDirection) {
    listWorkplaceCategories(sortDirection: $sortDirection) {
      items {
        id
        order
        name
        removedAt
      }
    }
  }
`;
export const searchChecksheetTemplatesByOffset = /* GraphQL */ `
  query SearchChecksheetTemplatesByOffset(
    $ownerCompanyId: ID
    $filter: SearchableChecksheetTemplateFilterInput
    $sort: SearchableChecksheetTemplateSortInput
    $offset: Int
    $limit: Int
  ) {
    searchChecksheetTemplatesByOffset(
      ownerCompanyId: $ownerCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        categoryName
        name
        totalOfItems
        updatedAt
        state
      }
      nextToken
      total
    }
  }
`;
export const searchChecksheetsByOffset = /* GraphQL */ `
  query SearchChecksheetsByOffset(
    $ownerCompanyId: ID
    $filter: SearchableChecksheetFilterInput
    $sort: SearchableChecksheetSortInput
    $offset: Int
    $limit: Int
  ) {
    searchChecksheetsByOffset(
      ownerCompanyId: $ownerCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        categoryName
        name
        personInChargeName
        checkedOn
        totalOfItems
        numberOfChecks
        numberOfUnchecks
        updatedAt
        state
      }
      nextToken
      total
    }
  }
`;
export const searchClientsByOffset = /* GraphQL */ `
  query SearchClientsByOffset(
    $supplierCompanyId: ID!
    $filter: SearchableClientFilterInput
    $sort: SearchableClientSortInput
    $offset: Int
    $limit: Int
  ) {
    searchClientsByOffset(
      supplierCompanyId: $supplierCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        name
        phoneticGuidesOfName
        corporateNumber
        representativeName
        establishmentedOn
        url
        remarks
        headOfficeWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        companyCategories {
          id
          order
          name
          removedAt
        }
        companyId
      }
      nextToken
      total
    }
  }
`;
export const searchCollectionPrecautionsByOffset = /* GraphQL */ `
  query SearchCollectionPrecautionsByOffset(
    $ownerCompanyId: ID
    $filter: SearchableCollectionPrecautionFilterInput
    $sort: SearchableCollectionPrecautionSortInput
    $offset: Int
    $limit: Int
  ) {
    searchCollectionPrecautionsByOffset(
      ownerCompanyId: $ownerCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        name
        message
        fileCount
        ownerCompanyId
        lastUpdatedOn
      }
      nextToken
      total
    }
  }
`;
export const searchContractsByOffset = /* GraphQL */ `
  query SearchContractsByOffset(
    $ownerCompanyId: ID
    $isFilteredForAlerts: Boolean
    $filter: SearchableContractFilterInput
    $sort: SearchableContractSortInput
    $offset: Int
    $limit: Int
  ) {
    searchContractsByOffset(
      ownerCompanyId: $ownerCompanyId
      isFilteredForAlerts: $isFilteredForAlerts
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        number
        name
        type {
          id
          order
          name
          removedAt
        }
        executedOn
        termOfAgreementStartedOn
        termOfAgreementExpirationOn
        isAutomaticallyRenewed
        remarks
        state
        ownerCompanyId
        lastUpdatedAt
        partyCompanies {
          id
          name
          phoneticGuidesOfName
        }
      }
      nextToken
      total
    }
  }
`;
export const searchWorkplaceIncludingClientsByOffset = /* GraphQL */ `
  query SearchWorkplaceIncludingClientsByOffset(
    $belongInCompanyId: ID
    $filter: SearchableWorkplaceFilterInput
    $sort: SearchableWorkplaceSortInput
    $offset: Int
    $limit: Int
  ) {
    searchWorkplaceIncludingClientsByOffset(
      belongInCompanyId: $belongInCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        name
        phoneticGuidesOfName
        code
        industrySector {
          id
          largeClass {
            code
            name
            removedAt
          }
          middleClass {
            code
            name
            removedAt
          }
          smallClass {
            code
            name
            removedAt
          }
          subClass {
            code
            name
            removedAt
          }
          removedAt
        }
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        remarks
        state
        belongInCompanyId
        belongInCompanyName
      }
      nextToken
      total
    }
  }
`;
export const searchWorkplacesByOffset = /* GraphQL */ `
  query SearchWorkplacesByOffset(
    $belongInCompanyId: ID
    $filter: SearchableWorkplaceFilterInput
    $sort: SearchableWorkplaceSortInput
    $offset: Int
    $limit: Int
  ) {
    searchWorkplacesByOffset(
      belongInCompanyId: $belongInCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        name
        phoneticGuidesOfName
        code
        industrySector {
          id
          largeClass {
            code
            name
            removedAt
          }
          middleClass {
            code
            name
            removedAt
          }
          smallClass {
            code
            name
            removedAt
          }
          subClass {
            code
            name
            removedAt
          }
          removedAt
        }
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        remarks
        state
        belongInCompanyId
        belongInCompanyName
      }
      nextToken
      total
    }
  }
`;
export const getManagementVehicle = /* GraphQL */ `
  query GetManagementVehicle($id: ID!) {
    getManagementVehicle(id: $id) {
      id
      modelName
      number
      parkingCertificateOwnerWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      certificateExpiredOn
      certificateUpdatedAt
      odometer
      instrumentUpdatedAt
    }
  }
`;
export const getVehicle = /* GraphQL */ `
  query GetVehicle($id: ID!) {
    getVehicle(id: $id) {
      id
      number
      modelName
      parkingCertificateOwnerWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      loadCapacity
      totalWeight
      ridingCapacity
      purchasedOn
      firstYearRegistrationOn
      remarks
      state
      ownerCompanyId
      version
    }
  }
`;
export const getVehicleMaintenanceRecord = /* GraphQL */ `
  query GetVehicleMaintenanceRecord($id: ID!) {
    getVehicleMaintenanceRecord(id: $id) {
      id
      vehicleId
      vehicleModelName
      vehicleNumber
      maintenanceWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      number
      category
      maintenancedOn
      price
      details {
        order
        method
        part
        content
        quantityValue
        quantityUnit
        remarks
      }
      files {
        name
        url
        order
        label
      }
      remarks
      state
      version
    }
  }
`;
export const getVehiclePeriodicInspection = /* GraphQL */ `
  query GetVehiclePeriodicInspection($id: ID!) {
    getVehiclePeriodicInspection(id: $id) {
      id
      vehicleId
      vehicleModelName
      vehicleNumber
      name
      description
      personInChargeName
      checkedOn
      sections {
        order
        name
        items {
          order
          name
          checked
        }
      }
      files {
        name
        url
        order
        label
      }
      remarks
      state
      version
    }
  }
`;
export const getVehicleRemind = /* GraphQL */ `
  query GetVehicleRemind($id: ID!) {
    getVehicleRemind(id: $id) {
      id
      name
      message
      criteria {
        and {
          date {
            gte
            lte
            eq
          }
          odometer {
            gte
            lte
            eq
          }
          certificateDays {
            gte
            lte
            eq
          }
        }
        or {
          date {
            gte
            lte
            eq
          }
          odometer {
            gte
            lte
            eq
          }
          certificateDays {
            gte
            lte
            eq
          }
        }
      }
      notifications {
        notificationAt
        repeatDays
        destinationEmails {
          email
          suppressionState
          suppressionedAt
        }
      }
      vehicleId
      version
    }
  }
`;
export const getVehicleSafetyInspectionRecord = /* GraphQL */ `
  query GetVehicleSafetyInspectionRecord($id: ID!) {
    getVehicleSafetyInspectionRecord(id: $id) {
      id
      vehicleId
      vehicleModelName
      vehicleNumber
      inspectionWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      receivedOn
      completedOn
      expiredOn
      price
      result
      files {
        name
        url
        order
        label
      }
      remarks
      state
      version
    }
  }
`;
export const listModelOfVehicles = /* GraphQL */ `
  query ListModelOfVehicles($sortDirection: ModelSortDirection) {
    listModelOfVehicles(sortDirection: $sortDirection) {
      items {
        id
        name
      }
    }
  }
`;
export const searchManagementVehiclesByOffset = /* GraphQL */ `
  query SearchManagementVehiclesByOffset(
    $ownerCompanyId: ID
    $filter: SearchableManagementVehicleFilterInput
    $sort: SearchableManagementVehicleSortInput
    $offset: Int
    $limit: Int
  ) {
    searchManagementVehiclesByOffset(
      ownerCompanyId: $ownerCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        modelName
        number
        parkingCertificateOwnerWorkplaceId
        parkingCertificateOwnerCompanyName
        parkingCertificateOwnerWorkplaceName
        certificateExpiredOn
        certificateUpdatedAt
        odometer
        instrumentUpdatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchVehicleMaintenanceRecordsByOffset = /* GraphQL */ `
  query SearchVehicleMaintenanceRecordsByOffset(
    $ownerCompanyId: ID
    $filter: SearchableVehicleMaintenanceRecordFilterInput
    $sort: SearchableVehicleMaintenanceRecordSortInput
    $offset: Int
    $limit: Int
  ) {
    searchVehicleMaintenanceRecordsByOffset(
      ownerCompanyId: $ownerCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        vehicleId
        vehicleModelName
        vehicleNumber
        maintenanceWorkplaceId
        maintenanceCompanyName
        maintenanceWorkplaceName
        number
        category
        maintenancedOn
        price
        state
      }
      nextToken
      total
    }
  }
`;
export const searchVehiclePeriodicInspectionsByOffset = /* GraphQL */ `
  query SearchVehiclePeriodicInspectionsByOffset(
    $ownerCompanyId: ID
    $filter: SearchableVehiclePeriodicInspectionFilterInput
    $sort: SearchableVehiclePeriodicInspectionSortInput
    $offset: Int
    $limit: Int
  ) {
    searchVehiclePeriodicInspectionsByOffset(
      ownerCompanyId: $ownerCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        vehicleModelName
        vehicleNumber
        checksheetName
        personInChargeName
        checkedOn
        totalOfItems
        numberOfChecks
        numberOfUnchecks
        updatedAt
        state
      }
      nextToken
      total
    }
  }
`;
export const searchVehicleRemindNotificationsByOffset = /* GraphQL */ `
  query SearchVehicleRemindNotificationsByOffset(
    $ownerCompanyId: ID
    $filter: SearchableVehicleRemindNotificationFilterInput
    $sort: SearchableVehicleRemindNotificationSortInput
    $offset: Int
    $limit: Int
  ) {
    searchVehicleRemindNotificationsByOffset(
      ownerCompanyId: $ownerCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        vehicleRemindId
        vehicleNumber
        vehicleModelName
        remindName
        message
        notificationAt
        vehicleId
      }
      nextToken
      total
    }
  }
`;
export const searchVehicleRemindsByOffset = /* GraphQL */ `
  query SearchVehicleRemindsByOffset(
    $vehicleId: ID
    $filter: SearchableVehicleRemindFilterInput
    $sort: SearchableVehicleRemindSortInput
    $offset: Int
    $limit: Int
  ) {
    searchVehicleRemindsByOffset(
      vehicleId: $vehicleId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        name
        message
        criteria {
          and {
            date {
              gte
              lte
              eq
            }
            odometer {
              gte
              lte
              eq
            }
            certificateDays {
              gte
              lte
              eq
            }
          }
          or {
            date {
              gte
              lte
              eq
            }
            odometer {
              gte
              lte
              eq
            }
            certificateDays {
              gte
              lte
              eq
            }
          }
        }
        vehicleId
      }
      nextToken
      total
    }
  }
`;
export const searchVehicleSafetyInspectionRecordsByOffset = /* GraphQL */ `
  query SearchVehicleSafetyInspectionRecordsByOffset(
    $ownerCompanyId: ID
    $filter: SearchableVehicleSafetyInspectionRecordFilterInput
    $sort: SearchableVehicleSafetyInspectionRecordSortInput
    $offset: Int
    $limit: Int
  ) {
    searchVehicleSafetyInspectionRecordsByOffset(
      ownerCompanyId: $ownerCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        vehicleId
        vehicleModelName
        vehicleNumber
        inspectionWorkplaceId
        inspectionCompanyName
        inspectionWorkplaceName
        receivedOn
        completedOn
        expiredOn
        price
        result
        state
      }
      nextToken
      total
    }
  }
`;
export const searchVehiclesByOffset = /* GraphQL */ `
  query SearchVehiclesByOffset(
    $ownerCompanyId: ID
    $filter: SearchableVehicleFilterInput
    $sort: SearchableVehicleSortInput
    $offset: Int
    $limit: Int
  ) {
    searchVehiclesByOffset(
      ownerCompanyId: $ownerCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        number
        modelName
        parkingCertificateOwnerWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        loadCapacity
        totalWeight
        ridingCapacity
        purchasedOn
        firstYearRegistrationOn
        remarks
        state
        ownerCompanyId
      }
      nextToken
      total
    }
  }
`;
export const getPlanSubscribeProcedure = /* GraphQL */ `
  query GetPlanSubscribeProcedure($id: ID!) {
    getPlanSubscribeProcedure(id: $id) {
      id
      companyName
      contactPersonnel
      email
      plan {
        id
        name
      }
      url
      expireAt
      state {
        id
        order
        name
        removedAt
      }
      version
    }
  }
`;
export const getPlanSubscriber = /* GraphQL */ `
  query GetPlanSubscriber($id: ID!) {
    getPlanSubscriber(id: $id) {
      id
      companyName
      contactPersonnel
      startedUsingAt
      endedUsingAt
      plan {
        id
        name
      }
      workplaceCount
      clientWorkplaceCount
      workplacesTotalCalculationDay
      state
      version
    }
  }
`;
export const listInquiries = /* GraphQL */ `
  query ListInquiries(
    $offset: Int
    $limit: Int
    $sortDirection: ModelSortDirection
  ) {
    listInquiries(
      offset: $offset
      limit: $limit
      sortDirection: $sortDirection
    ) {
      items {
        id
        inquiryProcessing {
          id
          order
          name
          removedAt
        }
        method
        receivedAt
        category
        companyName
        nameOfInquirer
        phone
        email
        message
        version
      }
    }
  }
`;
export const listInquiryProcessings = /* GraphQL */ `
  query ListInquiryProcessings($sortDirection: ModelSortDirection) {
    listInquiryProcessings(sortDirection: $sortDirection) {
      items {
        id
        order
        name
        removedAt
      }
    }
  }
`;
export const listInquiryProgress = /* GraphQL */ `
  query ListInquiryProgress(
    $inquiryId: ID!
    $offset: Int
    $limit: Int
    $sortDirection: ModelSortDirection
  ) {
    listInquiryProgress(
      inquiryId: $inquiryId
      offset: $offset
      limit: $limit
      sortDirection: $sortDirection
    ) {
      items {
        id
        inquiryId
        progressedAt
        inquiryProcessing {
          id
          order
          name
          removedAt
        }
        details
      }
    }
  }
`;
export const listPlanSubscribeProcedureStatuses = /* GraphQL */ `
  query ListPlanSubscribeProcedureStatuses($sortDirection: ModelSortDirection) {
    listPlanSubscribeProcedureStatuses(sortDirection: $sortDirection) {
      items {
        id
        order
        name
        removedAt
      }
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans($sortDirection: ModelSortDirection) {
    listPlans(sortDirection: $sortDirection) {
      items {
        id
        name
        description
      }
    }
  }
`;
export const listSystemNotifications = /* GraphQL */ `
  query ListSystemNotifications(
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystemNotifications(
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        validityStartAt
        validityEndAt
        message
      }
      nextToken
    }
  }
`;
export const listWorkplacesTotals = /* GraphQL */ `
  query ListWorkplacesTotals(
    $planSubscriberId: ID!
    $offset: Int
    $limit: Int
    $sortDirection: ModelSortDirection
  ) {
    listWorkplacesTotals(
      planSubscriberId: $planSubscriberId
      offset: $offset
      limit: $limit
      sortDirection: $sortDirection
    ) {
      items {
        planSubscriberId
        calculatedOn
        workplaceCount
        clientWorkplaceCount
      }
    }
  }
`;
export const searchInquiriesByOffset = /* GraphQL */ `
  query SearchInquiriesByOffset(
    $filter: SearchableInquiryFilterInput
    $sort: SearchableInquirySortInput
    $offset: Int
    $limit: Int
  ) {
    searchInquiriesByOffset(
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        inquiryProcessing {
          id
          order
          name
          removedAt
        }
        method
        receivedAt
        category
        companyName
        nameOfInquirer
        phone
        email
        message
        version
      }
      nextToken
      total
    }
  }
`;
export const searchPlanSubscribeProceduresByOffset = /* GraphQL */ `
  query SearchPlanSubscribeProceduresByOffset(
    $filter: SearchablePlanSubscribeProcedureFilterInput
    $sort: SearchablePlanSubscribeProcedureSortInput
    $offset: Int
    $limit: Int
  ) {
    searchPlanSubscribeProceduresByOffset(
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        companyName
        contactPersonnel
        email
        plan {
          id
          name
        }
        expireAt
        state {
          id
          order
          name
          removedAt
        }
        createdAt
      }
      nextToken
      total
    }
  }
`;
export const searchPlanSubscribersByOffset = /* GraphQL */ `
  query SearchPlanSubscribersByOffset(
    $filter: SearchablePlanSubscriberFilterInput
    $sort: SearchablePlanSubscriberSortInput
    $offset: Int
    $limit: Int
  ) {
    searchPlanSubscribersByOffset(
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        companyName
        contactPersonnel
        startedUsingAt
        endedUsingAt
        plan {
          id
          name
        }
        workplaceCount
        clientWorkplaceCount
        workplacesTotalCalculationDay
        lastTotalCalculatedOn
        lastTotalWorkplaceCount
        state
      }
      nextToken
      total
    }
  }
`;
export const getJwnetManifest = /* GraphQL */ `
  query GetJwnetManifest($id: ID!) {
    getJwnetManifest(id: $id) {
      id
      manifestNumber
      passedOverOn
      billedOn
      registeredOn
      changedOn
      canceledOn
      lastUpdatedOn
      lastDisposalEndedOn
      lastDisposalReportedOn
      transportDisposalReportingDeadline
      lastDisposalReportingDeadline
      bookedExpirationDate
      transportReportCompleted
      disposalReportCompleted
      lastDisposalReportCompleted
      isLocked
      manifestInfoType {
        code
        name
        removedAt
      }
      manifestApprovalPendingStatus {
        code
        name
        removedAt
      }
      manifestDisposalReportType {
        code
        name
        removedAt
      }
      bookedManifestChangePermission {
        code
        name
        removedAt
      }
      manifestLastDisposalPlantDescribeType {
        code
        name
        removedAt
      }
      manifestIntermediateProcessingManagementMethod {
        code
        name
        removedAt
      }
      manifestCancellationStatus {
        code
        name
        removedAt
      }
      contractNumbers {
        number
        value
      }
      remarks {
        number
        value
      }
      plannedLastDisposalPlants {
        name
        postalCode
        prefecturesName
        city
        streetAddress
        otherAddress
        phone
      }
      primaryManifests {
        manifestType {
          code
          name
          removedAt
        }
        manifestNumber
        issueNumber
        contactNumber
        issuedOn
        disposalEndedOn
        dischargeCompanyName
        dischargeWorkplaceName
        wasteKind
        wasteQuantity
        wasteQuantityUnit {
          code
          name
          removedAt
        }
      }
      waste {
        wasteTypeLargeClassCode
        wasteTypeMiddleClassCode
        wasteTypeSmallClassCode
        wasteTypeSubClassCode
        categoryName
        kind
        name
        quantity
        wasteQuantityUnit {
          code
          name
          removedAt
        }
        jwnetManifestQuantityDeterminer {
          code
          name
          removedAt
        }
        confirmedQuantity
        confirmedWasteQuantityUnit {
          code
          name
          removedAt
        }
        wastePacking {
          code
          name
          removedAt
        }
        packingName
        packingQuantity
        hazardousSubstances {
          type {
            code
            name
            removedAt
          }
          name
        }
        radioactiveSubstances {
          type {
            code
            name
            removedAt
          }
          name
          measurementType {
            code
            name
            removedAt
          }
          detectionType {
            code
            name
            removedAt
          }
          radioactiveValue
          radiationValueUnit {
            code
            name
            removedAt
          }
          radiationValueUnitName
          remarks
        }
      }
      wasteDischarge {
        companyMemberNumber
        companyName
        companyPostalCode
        companyPrefecturesName
        companyCity
        companyStreetAddress
        companyOtherAddress
        companyPhone
        companyFax
        workplaceName
        workplacePostalCode
        workplacePrefecturesName
        workplaceCity
        workplaceStreetAddress
        workplaceOtherAddress
        workplacePhone
        registeredPerson
        passedPerson
      }
      wasteTransportSections {
        sectionNumber
        carrier {
          memberNumber
          name
          postalCode
          prefecturesName
          city
          streetAddress
          otherAddress
          phone
          fax
          unificationPermitNumber
          subcontractorMemberNumber
          subcontractorName
          subcontractorPostalCode
          subcontractorPrefecturesName
          subcontractorCity
          subcontractorStreetAddress
          subcontractorOtherAddress
          subcontractorPhone
          subcontractorFax
          subcontractorUnificationPermitNumber
          wasteTransportMethod {
            code
            name
            removedAt
          }
          transportPersonInCharge
          vehicleNumber
        }
        destination {
          destinationType {
            code
            name
            removedAt
          }
          memberNumber
          memberName
          workplaceNumber
          workplaceName
          workplacePostalCode
          workplacePrefecturesName
          workplaceCity
          workplaceStreetAddress
          workplaceOtherAddress
          workplacePhone
        }
        report {
          manifestApprovalPendingStatus {
            code
            name
            removedAt
          }
          transportationEndedOn
          personInChargeListed
          vehicleNumberListed
          transportQuantity
          transportWasteQuantityUnit {
            code
            name
            removedAt
          }
          valuablesQuantity
          valuablesQuantityUnit {
            code
            name
            removedAt
          }
          reporter
          remarks
        }
        reportable
      }
      wasteDisposal {
        memberNumber
        name
        postalCode
        prefecturesName
        city
        streetAddress
        otherAddress
        phone
        fax
        unificationPermitNumber
        subcontractorMemberNumber
        subcontractorName
        subcontractorPostalCode
        subcontractorPrefecturesName
        subcontractorCity
        subcontractorStreetAddress
        subcontractorOtherAddress
        subcontractorPhone
        subcontractorFax
        subcontractorUnificationPermitNumber
        wasteDisposalMethod {
          code
          largeClassName
          middleClassName
          smallClassName
          removedAt
        }
        disposalMethodName
        report {
          manifestApprovalPendingStatus {
            code
            name
            removedAt
          }
          disposalEndedOn
          wasteReceiptedOn
          receivingQuantity
          receivingWasteQuantityUnit {
            code
            name
            removedAt
          }
          transportPersonInCharge
          vehicleNumber
          reporter
          disposalPersonInCharge
          reportedOn
          remarks
        }
      }
      wasteLastDisposals {
        disposalPlantName
        disposalPlantPostalCode
        disposalPlantPrefecturesName
        disposalPlantCity
        disposalPlantStreetAddress
        disposalPlantOtherAddress
        disposalPlantPhone
        lastDisposalEndedOn
      }
      approvalPendingDetail {
        id
        manifestNumber
        passedOverOn
        billedOn
        registeredOn
        changedOn
        canceledOn
        lastUpdatedOn
        lastDisposalEndedOn
        lastDisposalReportedOn
        transportDisposalReportingDeadline
        lastDisposalReportingDeadline
        bookedExpirationDate
        transportReportCompleted
        disposalReportCompleted
        lastDisposalReportCompleted
        isLocked
        manifestInfoType {
          code
          name
          removedAt
        }
        manifestApprovalPendingStatus {
          code
          name
          removedAt
        }
        manifestDisposalReportType {
          code
          name
          removedAt
        }
        bookedManifestChangePermission {
          code
          name
          removedAt
        }
        manifestLastDisposalPlantDescribeType {
          code
          name
          removedAt
        }
        manifestIntermediateProcessingManagementMethod {
          code
          name
          removedAt
        }
        manifestCancellationStatus {
          code
          name
          removedAt
        }
        contractNumbers {
          number
          value
        }
        remarks {
          number
          value
        }
        plannedLastDisposalPlants {
          name
          postalCode
          prefecturesName
          city
          streetAddress
          otherAddress
          phone
        }
        primaryManifests {
          manifestType {
            code
            name
            removedAt
          }
          manifestNumber
          issueNumber
          contactNumber
          issuedOn
          disposalEndedOn
          dischargeCompanyName
          dischargeWorkplaceName
          wasteKind
          wasteQuantity
          wasteQuantityUnit {
            code
            name
            removedAt
          }
        }
        waste {
          wasteTypeLargeClassCode
          wasteTypeMiddleClassCode
          wasteTypeSmallClassCode
          wasteTypeSubClassCode
          categoryName
          kind
          name
          quantity
          wasteQuantityUnit {
            code
            name
            removedAt
          }
          jwnetManifestQuantityDeterminer {
            code
            name
            removedAt
          }
          confirmedQuantity
          confirmedWasteQuantityUnit {
            code
            name
            removedAt
          }
          wastePacking {
            code
            name
            removedAt
          }
          packingName
          packingQuantity
          hazardousSubstances {
            type {
              code
              name
              removedAt
            }
            name
          }
          radioactiveSubstances {
            type {
              code
              name
              removedAt
            }
            name
            measurementType {
              code
              name
              removedAt
            }
            detectionType {
              code
              name
              removedAt
            }
            radioactiveValue
            radiationValueUnit {
              code
              name
              removedAt
            }
            radiationValueUnitName
            remarks
          }
        }
        wasteDischarge {
          companyMemberNumber
          companyName
          companyPostalCode
          companyPrefecturesName
          companyCity
          companyStreetAddress
          companyOtherAddress
          companyPhone
          companyFax
          workplaceName
          workplacePostalCode
          workplacePrefecturesName
          workplaceCity
          workplaceStreetAddress
          workplaceOtherAddress
          workplacePhone
          registeredPerson
          passedPerson
        }
        wasteTransportSections {
          sectionNumber
          carrier {
            memberNumber
            name
            postalCode
            prefecturesName
            city
            streetAddress
            otherAddress
            phone
            fax
            unificationPermitNumber
            subcontractorMemberNumber
            subcontractorName
            subcontractorPostalCode
            subcontractorPrefecturesName
            subcontractorCity
            subcontractorStreetAddress
            subcontractorOtherAddress
            subcontractorPhone
            subcontractorFax
            subcontractorUnificationPermitNumber
            wasteTransportMethod {
              code
              name
              removedAt
            }
            transportPersonInCharge
            vehicleNumber
          }
          destination {
            destinationType {
              code
              name
              removedAt
            }
            memberNumber
            memberName
            workplaceNumber
            workplaceName
            workplacePostalCode
            workplacePrefecturesName
            workplaceCity
            workplaceStreetAddress
            workplaceOtherAddress
            workplacePhone
          }
          report {
            manifestApprovalPendingStatus {
              code
              name
              removedAt
            }
            transportationEndedOn
            personInChargeListed
            vehicleNumberListed
            transportQuantity
            transportWasteQuantityUnit {
              code
              name
              removedAt
            }
            valuablesQuantity
            valuablesQuantityUnit {
              code
              name
              removedAt
            }
            reporter
            remarks
          }
          reportable
        }
        wasteDisposal {
          memberNumber
          name
          postalCode
          prefecturesName
          city
          streetAddress
          otherAddress
          phone
          fax
          unificationPermitNumber
          subcontractorMemberNumber
          subcontractorName
          subcontractorPostalCode
          subcontractorPrefecturesName
          subcontractorCity
          subcontractorStreetAddress
          subcontractorOtherAddress
          subcontractorPhone
          subcontractorFax
          subcontractorUnificationPermitNumber
          wasteDisposalMethod {
            code
            largeClassName
            middleClassName
            smallClassName
            removedAt
          }
          disposalMethodName
          report {
            manifestApprovalPendingStatus {
              code
              name
              removedAt
            }
            disposalEndedOn
            wasteReceiptedOn
            receivingQuantity
            receivingWasteQuantityUnit {
              code
              name
              removedAt
            }
            transportPersonInCharge
            vehicleNumber
            reporter
            disposalPersonInCharge
            reportedOn
            remarks
          }
        }
        wasteLastDisposals {
          disposalPlantName
          disposalPlantPostalCode
          disposalPlantPrefecturesName
          disposalPlantCity
          disposalPlantStreetAddress
          disposalPlantOtherAddress
          disposalPlantPhone
          lastDisposalEndedOn
        }
        approvalPendingDetail {
          id
          manifestNumber
          passedOverOn
          billedOn
          registeredOn
          changedOn
          canceledOn
          lastUpdatedOn
          lastDisposalEndedOn
          lastDisposalReportedOn
          transportDisposalReportingDeadline
          lastDisposalReportingDeadline
          bookedExpirationDate
          transportReportCompleted
          disposalReportCompleted
          lastDisposalReportCompleted
          isLocked
          manifestInfoType {
            code
            name
            removedAt
          }
          manifestApprovalPendingStatus {
            code
            name
            removedAt
          }
          manifestDisposalReportType {
            code
            name
            removedAt
          }
          bookedManifestChangePermission {
            code
            name
            removedAt
          }
          manifestLastDisposalPlantDescribeType {
            code
            name
            removedAt
          }
          manifestIntermediateProcessingManagementMethod {
            code
            name
            removedAt
          }
          manifestCancellationStatus {
            code
            name
            removedAt
          }
          contractNumbers {
            number
            value
          }
          remarks {
            number
            value
          }
          plannedLastDisposalPlants {
            name
            postalCode
            prefecturesName
            city
            streetAddress
            otherAddress
            phone
          }
          primaryManifests {
            manifestType {
              code
              name
              removedAt
            }
            manifestNumber
            issueNumber
            contactNumber
            issuedOn
            disposalEndedOn
            dischargeCompanyName
            dischargeWorkplaceName
            wasteKind
            wasteQuantity
            wasteQuantityUnit {
              code
              name
              removedAt
            }
          }
          waste {
            wasteTypeLargeClassCode
            wasteTypeMiddleClassCode
            wasteTypeSmallClassCode
            wasteTypeSubClassCode
            categoryName
            kind
            name
            quantity
            wasteQuantityUnit {
              code
              name
              removedAt
            }
            jwnetManifestQuantityDeterminer {
              code
              name
              removedAt
            }
            confirmedQuantity
            confirmedWasteQuantityUnit {
              code
              name
              removedAt
            }
            wastePacking {
              code
              name
              removedAt
            }
            packingName
            packingQuantity
            hazardousSubstances {
              type {
                code
                name
                removedAt
              }
              name
            }
            radioactiveSubstances {
              type {
                code
                name
                removedAt
              }
              name
              measurementType {
                code
                name
                removedAt
              }
              detectionType {
                code
                name
                removedAt
              }
              radioactiveValue
              radiationValueUnit {
                code
                name
                removedAt
              }
              radiationValueUnitName
              remarks
            }
          }
          wasteDischarge {
            companyMemberNumber
            companyName
            companyPostalCode
            companyPrefecturesName
            companyCity
            companyStreetAddress
            companyOtherAddress
            companyPhone
            companyFax
            workplaceName
            workplacePostalCode
            workplacePrefecturesName
            workplaceCity
            workplaceStreetAddress
            workplaceOtherAddress
            workplacePhone
            registeredPerson
            passedPerson
          }
          wasteTransportSections {
            sectionNumber
            carrier {
              memberNumber
              name
              postalCode
              prefecturesName
              city
              streetAddress
              otherAddress
              phone
              fax
              unificationPermitNumber
              subcontractorMemberNumber
              subcontractorName
              subcontractorPostalCode
              subcontractorPrefecturesName
              subcontractorCity
              subcontractorStreetAddress
              subcontractorOtherAddress
              subcontractorPhone
              subcontractorFax
              subcontractorUnificationPermitNumber
              wasteTransportMethod {
                code
                name
                removedAt
              }
              transportPersonInCharge
              vehicleNumber
            }
            destination {
              destinationType {
                code
                name
                removedAt
              }
              memberNumber
              memberName
              workplaceNumber
              workplaceName
              workplacePostalCode
              workplacePrefecturesName
              workplaceCity
              workplaceStreetAddress
              workplaceOtherAddress
              workplacePhone
            }
            report {
              manifestApprovalPendingStatus {
                code
                name
                removedAt
              }
              transportationEndedOn
              personInChargeListed
              vehicleNumberListed
              transportQuantity
              transportWasteQuantityUnit {
                code
                name
                removedAt
              }
              valuablesQuantity
              valuablesQuantityUnit {
                code
                name
                removedAt
              }
              reporter
              remarks
            }
            reportable
          }
          wasteDisposal {
            memberNumber
            name
            postalCode
            prefecturesName
            city
            streetAddress
            otherAddress
            phone
            fax
            unificationPermitNumber
            subcontractorMemberNumber
            subcontractorName
            subcontractorPostalCode
            subcontractorPrefecturesName
            subcontractorCity
            subcontractorStreetAddress
            subcontractorOtherAddress
            subcontractorPhone
            subcontractorFax
            subcontractorUnificationPermitNumber
            wasteDisposalMethod {
              code
              largeClassName
              middleClassName
              smallClassName
              removedAt
            }
            disposalMethodName
            report {
              manifestApprovalPendingStatus {
                code
                name
                removedAt
              }
              disposalEndedOn
              wasteReceiptedOn
              receivingQuantity
              receivingWasteQuantityUnit {
                code
                name
                removedAt
              }
              transportPersonInCharge
              vehicleNumber
              reporter
              disposalPersonInCharge
              reportedOn
              remarks
            }
          }
          wasteLastDisposals {
            disposalPlantName
            disposalPlantPostalCode
            disposalPlantPrefecturesName
            disposalPlantCity
            disposalPlantStreetAddress
            disposalPlantOtherAddress
            disposalPlantPhone
            lastDisposalEndedOn
          }
          approvalPendingDetail {
            id
            manifestNumber
            passedOverOn
            billedOn
            registeredOn
            changedOn
            canceledOn
            lastUpdatedOn
            lastDisposalEndedOn
            lastDisposalReportedOn
            transportDisposalReportingDeadline
            lastDisposalReportingDeadline
            bookedExpirationDate
            transportReportCompleted
            disposalReportCompleted
            lastDisposalReportCompleted
            isLocked
            manifestInfoType {
              code
              name
              removedAt
            }
            manifestApprovalPendingStatus {
              code
              name
              removedAt
            }
            manifestDisposalReportType {
              code
              name
              removedAt
            }
            bookedManifestChangePermission {
              code
              name
              removedAt
            }
            manifestLastDisposalPlantDescribeType {
              code
              name
              removedAt
            }
            manifestIntermediateProcessingManagementMethod {
              code
              name
              removedAt
            }
            manifestCancellationStatus {
              code
              name
              removedAt
            }
            contractNumbers {
              number
              value
            }
            remarks {
              number
              value
            }
            plannedLastDisposalPlants {
              name
              postalCode
              prefecturesName
              city
              streetAddress
              otherAddress
              phone
            }
            primaryManifests {
              manifestType {
                code
                name
                removedAt
              }
              manifestNumber
              issueNumber
              contactNumber
              issuedOn
              disposalEndedOn
              dischargeCompanyName
              dischargeWorkplaceName
              wasteKind
              wasteQuantity
              wasteQuantityUnit {
                code
                name
                removedAt
              }
            }
            waste {
              wasteTypeLargeClassCode
              wasteTypeMiddleClassCode
              wasteTypeSmallClassCode
              wasteTypeSubClassCode
              categoryName
              kind
              name
              quantity
              wasteQuantityUnit {
                code
                name
                removedAt
              }
              jwnetManifestQuantityDeterminer {
                code
                name
                removedAt
              }
              confirmedQuantity
              confirmedWasteQuantityUnit {
                code
                name
                removedAt
              }
              wastePacking {
                code
                name
                removedAt
              }
              packingName
              packingQuantity
              hazardousSubstances {
                type {
                  code
                  name
                  removedAt
                }
                name
              }
              radioactiveSubstances {
                type {
                  code
                  name
                  removedAt
                }
                name
                measurementType {
                  code
                  name
                  removedAt
                }
                detectionType {
                  code
                  name
                  removedAt
                }
                radioactiveValue
                radiationValueUnit {
                  code
                  name
                  removedAt
                }
                radiationValueUnitName
                remarks
              }
            }
            wasteDischarge {
              companyMemberNumber
              companyName
              companyPostalCode
              companyPrefecturesName
              companyCity
              companyStreetAddress
              companyOtherAddress
              companyPhone
              companyFax
              workplaceName
              workplacePostalCode
              workplacePrefecturesName
              workplaceCity
              workplaceStreetAddress
              workplaceOtherAddress
              workplacePhone
              registeredPerson
              passedPerson
            }
            wasteTransportSections {
              sectionNumber
              carrier {
                memberNumber
                name
                postalCode
                prefecturesName
                city
                streetAddress
                otherAddress
                phone
                fax
                unificationPermitNumber
                subcontractorMemberNumber
                subcontractorName
                subcontractorPostalCode
                subcontractorPrefecturesName
                subcontractorCity
                subcontractorStreetAddress
                subcontractorOtherAddress
                subcontractorPhone
                subcontractorFax
                subcontractorUnificationPermitNumber
                wasteTransportMethod {
                  code
                  name
                  removedAt
                }
                transportPersonInCharge
                vehicleNumber
              }
              destination {
                destinationType {
                  code
                  name
                  removedAt
                }
                memberNumber
                memberName
                workplaceNumber
                workplaceName
                workplacePostalCode
                workplacePrefecturesName
                workplaceCity
                workplaceStreetAddress
                workplaceOtherAddress
                workplacePhone
              }
              report {
                manifestApprovalPendingStatus {
                  code
                  name
                  removedAt
                }
                transportationEndedOn
                personInChargeListed
                vehicleNumberListed
                transportQuantity
                transportWasteQuantityUnit {
                  code
                  name
                  removedAt
                }
                valuablesQuantity
                valuablesQuantityUnit {
                  code
                  name
                  removedAt
                }
                reporter
                remarks
              }
              reportable
            }
            wasteDisposal {
              memberNumber
              name
              postalCode
              prefecturesName
              city
              streetAddress
              otherAddress
              phone
              fax
              unificationPermitNumber
              subcontractorMemberNumber
              subcontractorName
              subcontractorPostalCode
              subcontractorPrefecturesName
              subcontractorCity
              subcontractorStreetAddress
              subcontractorOtherAddress
              subcontractorPhone
              subcontractorFax
              subcontractorUnificationPermitNumber
              wasteDisposalMethod {
                code
                largeClassName
                middleClassName
                smallClassName
                removedAt
              }
              disposalMethodName
              report {
                manifestApprovalPendingStatus {
                  code
                  name
                  removedAt
                }
                disposalEndedOn
                wasteReceiptedOn
                receivingQuantity
                receivingWasteQuantityUnit {
                  code
                  name
                  removedAt
                }
                transportPersonInCharge
                vehicleNumber
                reporter
                disposalPersonInCharge
                reportedOn
                remarks
              }
            }
            wasteLastDisposals {
              disposalPlantName
              disposalPlantPostalCode
              disposalPlantPrefecturesName
              disposalPlantCity
              disposalPlantStreetAddress
              disposalPlantOtherAddress
              disposalPlantPhone
              lastDisposalEndedOn
            }
            approvalPendingDetail {
              id
              manifestNumber
              passedOverOn
              billedOn
              registeredOn
              changedOn
              canceledOn
              lastUpdatedOn
              lastDisposalEndedOn
              lastDisposalReportedOn
              transportDisposalReportingDeadline
              lastDisposalReportingDeadline
              bookedExpirationDate
              transportReportCompleted
              disposalReportCompleted
              lastDisposalReportCompleted
              isLocked
              manifestInfoType {
                code
                name
                removedAt
              }
              manifestApprovalPendingStatus {
                code
                name
                removedAt
              }
              manifestDisposalReportType {
                code
                name
                removedAt
              }
              bookedManifestChangePermission {
                code
                name
                removedAt
              }
              manifestLastDisposalPlantDescribeType {
                code
                name
                removedAt
              }
              manifestIntermediateProcessingManagementMethod {
                code
                name
                removedAt
              }
              manifestCancellationStatus {
                code
                name
                removedAt
              }
              contractNumbers {
                number
                value
              }
              remarks {
                number
                value
              }
              plannedLastDisposalPlants {
                name
                postalCode
                prefecturesName
                city
                streetAddress
                otherAddress
                phone
              }
              primaryManifests {
                manifestType {
                  code
                  name
                  removedAt
                }
                manifestNumber
                issueNumber
                contactNumber
                issuedOn
                disposalEndedOn
                dischargeCompanyName
                dischargeWorkplaceName
                wasteKind
                wasteQuantity
                wasteQuantityUnit {
                  code
                  name
                  removedAt
                }
              }
              waste {
                wasteTypeLargeClassCode
                wasteTypeMiddleClassCode
                wasteTypeSmallClassCode
                wasteTypeSubClassCode
                categoryName
                kind
                name
                quantity
                wasteQuantityUnit {
                  code
                  name
                  removedAt
                }
                jwnetManifestQuantityDeterminer {
                  code
                  name
                  removedAt
                }
                confirmedQuantity
                confirmedWasteQuantityUnit {
                  code
                  name
                  removedAt
                }
                wastePacking {
                  code
                  name
                  removedAt
                }
                packingName
                packingQuantity
                hazardousSubstances {
                  name
                }
                radioactiveSubstances {
                  name
                  radioactiveValue
                  radiationValueUnitName
                  remarks
                }
              }
              wasteDischarge {
                companyMemberNumber
                companyName
                companyPostalCode
                companyPrefecturesName
                companyCity
                companyStreetAddress
                companyOtherAddress
                companyPhone
                companyFax
                workplaceName
                workplacePostalCode
                workplacePrefecturesName
                workplaceCity
                workplaceStreetAddress
                workplaceOtherAddress
                workplacePhone
                registeredPerson
                passedPerson
              }
              wasteTransportSections {
                sectionNumber
                carrier {
                  memberNumber
                  name
                  postalCode
                  prefecturesName
                  city
                  streetAddress
                  otherAddress
                  phone
                  fax
                  unificationPermitNumber
                  subcontractorMemberNumber
                  subcontractorName
                  subcontractorPostalCode
                  subcontractorPrefecturesName
                  subcontractorCity
                  subcontractorStreetAddress
                  subcontractorOtherAddress
                  subcontractorPhone
                  subcontractorFax
                  subcontractorUnificationPermitNumber
                  transportPersonInCharge
                  vehicleNumber
                }
                destination {
                  memberNumber
                  memberName
                  workplaceNumber
                  workplaceName
                  workplacePostalCode
                  workplacePrefecturesName
                  workplaceCity
                  workplaceStreetAddress
                  workplaceOtherAddress
                  workplacePhone
                }
                report {
                  transportationEndedOn
                  personInChargeListed
                  vehicleNumberListed
                  transportQuantity
                  valuablesQuantity
                  reporter
                  remarks
                }
                reportable
              }
              wasteDisposal {
                memberNumber
                name
                postalCode
                prefecturesName
                city
                streetAddress
                otherAddress
                phone
                fax
                unificationPermitNumber
                subcontractorMemberNumber
                subcontractorName
                subcontractorPostalCode
                subcontractorPrefecturesName
                subcontractorCity
                subcontractorStreetAddress
                subcontractorOtherAddress
                subcontractorPhone
                subcontractorFax
                subcontractorUnificationPermitNumber
                wasteDisposalMethod {
                  code
                  largeClassName
                  middleClassName
                  smallClassName
                  removedAt
                }
                disposalMethodName
                report {
                  disposalEndedOn
                  wasteReceiptedOn
                  receivingQuantity
                  transportPersonInCharge
                  vehicleNumber
                  reporter
                  disposalPersonInCharge
                  reportedOn
                  remarks
                }
              }
              wasteLastDisposals {
                disposalPlantName
                disposalPlantPostalCode
                disposalPlantPrefecturesName
                disposalPlantCity
                disposalPlantStreetAddress
                disposalPlantOtherAddress
                disposalPlantPhone
                lastDisposalEndedOn
              }
              approvalPendingDetail {
                id
                manifestNumber
                passedOverOn
                billedOn
                registeredOn
                changedOn
                canceledOn
                lastUpdatedOn
                lastDisposalEndedOn
                lastDisposalReportedOn
                transportDisposalReportingDeadline
                lastDisposalReportingDeadline
                bookedExpirationDate
                transportReportCompleted
                disposalReportCompleted
                lastDisposalReportCompleted
                isLocked
                manifestInfoType {
                  code
                  name
                  removedAt
                }
                manifestApprovalPendingStatus {
                  code
                  name
                  removedAt
                }
                manifestDisposalReportType {
                  code
                  name
                  removedAt
                }
                bookedManifestChangePermission {
                  code
                  name
                  removedAt
                }
                manifestLastDisposalPlantDescribeType {
                  code
                  name
                  removedAt
                }
                manifestIntermediateProcessingManagementMethod {
                  code
                  name
                  removedAt
                }
                manifestCancellationStatus {
                  code
                  name
                  removedAt
                }
                contractNumbers {
                  number
                  value
                }
                remarks {
                  number
                  value
                }
                plannedLastDisposalPlants {
                  name
                  postalCode
                  prefecturesName
                  city
                  streetAddress
                  otherAddress
                  phone
                }
                primaryManifests {
                  manifestNumber
                  issueNumber
                  contactNumber
                  issuedOn
                  disposalEndedOn
                  dischargeCompanyName
                  dischargeWorkplaceName
                  wasteKind
                  wasteQuantity
                }
                waste {
                  wasteTypeLargeClassCode
                  wasteTypeMiddleClassCode
                  wasteTypeSmallClassCode
                  wasteTypeSubClassCode
                  categoryName
                  kind
                  name
                  quantity
                  confirmedQuantity
                  packingName
                  packingQuantity
                }
                wasteDischarge {
                  companyMemberNumber
                  companyName
                  companyPostalCode
                  companyPrefecturesName
                  companyCity
                  companyStreetAddress
                  companyOtherAddress
                  companyPhone
                  companyFax
                  workplaceName
                  workplacePostalCode
                  workplacePrefecturesName
                  workplaceCity
                  workplaceStreetAddress
                  workplaceOtherAddress
                  workplacePhone
                  registeredPerson
                  passedPerson
                }
                wasteTransportSections {
                  sectionNumber
                  reportable
                }
                wasteDisposal {
                  memberNumber
                  name
                  postalCode
                  prefecturesName
                  city
                  streetAddress
                  otherAddress
                  phone
                  fax
                  unificationPermitNumber
                  subcontractorMemberNumber
                  subcontractorName
                  subcontractorPostalCode
                  subcontractorPrefecturesName
                  subcontractorCity
                  subcontractorStreetAddress
                  subcontractorOtherAddress
                  subcontractorPhone
                  subcontractorFax
                  subcontractorUnificationPermitNumber
                  disposalMethodName
                }
                wasteLastDisposals {
                  disposalPlantName
                  disposalPlantPostalCode
                  disposalPlantPrefecturesName
                  disposalPlantCity
                  disposalPlantStreetAddress
                  disposalPlantOtherAddress
                  disposalPlantPhone
                  lastDisposalEndedOn
                }
                approvalPendingDetail {
                  id
                  manifestNumber
                  passedOverOn
                  billedOn
                  registeredOn
                  changedOn
                  canceledOn
                  lastUpdatedOn
                  lastDisposalEndedOn
                  lastDisposalReportedOn
                  transportDisposalReportingDeadline
                  lastDisposalReportingDeadline
                  bookedExpirationDate
                  transportReportCompleted
                  disposalReportCompleted
                  lastDisposalReportCompleted
                  isLocked
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const getJwnetMember = /* GraphQL */ `
  query GetJwnetMember {
    getJwnetMember {
      id
      number
      ediUseConfirmationKey
      publicConfirmationNumber
      ediValidity
      version
    }
  }
`;
export const getPaperManifest = /* GraphQL */ `
  query GetPaperManifest($id: ID) {
    getPaperManifest(id: $id) {
      id
      paperManifestType {
        code
        name
        removedAt
      }
      manifestInfoType {
        code
        name
        removedAt
      }
      manifestCardinalNumberType {
        code
        name
        removedAt
      }
      issueDate
      issueNumber
      issuePersonInCharge
      referenceNumber
      intermediateProcessingManagementType {
        code
        name
        removedAt
      }
      lastDisposalPlantDescribeType {
        code
        name
        removedAt
      }
      isTransshipmentStorageUsing
      wasteGeneratorCompany {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteGeneratorWorkplace {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      waste {
        types {
          wasteType {
            id
            largeClass {
              code
              name
              removedAt
            }
            middleClass {
              code
              name
              removedAt
            }
            smallClass {
              code
              name
              removedAt
            }
            group {
              id
              name
              removedAt
            }
            removedAt
          }
          wasteTypeName
        }
        name
        quantityValue
        quantityUnit {
          code
          name
          removedAt
        }
        quantityUnitName
        packingName
        disposalMethodName
        hazardousSubstanceName
        remarks
      }
      primaryManifestRecord {
        primaryManifestIssuerName
        primaryManifestIssueNumber
        primaryManifestType {
          code
          name
          removedAt
        }
      }
      plannedLastDisposalPlant {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteCarrierCompany {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteTransportDestination {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteTransshipmentStorage {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteDisposalCompany {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteTransportReport {
        wasteCarrierCompanyName
        wasteCarrierPersonInCharge
        endOfTransportationDate
        valuablesQuantityValue
        valuablesQuantityUnit {
          code
          name
          removedAt
        }
        valuablesQuantityUnitName
      }
      wasteDisposalReport {
        wasteDisposalCompanyName
        wasteDisposalPersonInCharge
        endOfDisposalDate
        endOfLastDisposalDate
      }
      wasteLastDisposalPlant {
        contractNumber
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      relation {
        wasteGeneratorCompanyId
        wasteGeneratorWorkplaceId
        wasteId
        wasteFlowId
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const listJwnetManifestRequests = /* GraphQL */ `
  query ListJwnetManifestRequests($manifestId: ID!) {
    listJwnetManifestRequests(manifestId: $manifestId) {
      items {
        id
        sectionNumber
        functionName
        createdAt
        updatedAt
      }
    }
  }
`;
export const listWasteDisposalMethods = /* GraphQL */ `
  query ListWasteDisposalMethods($sortDirection: ModelSortDirection) {
    listWasteDisposalMethods(sortDirection: $sortDirection) {
      items {
        code
        largeClassName
        middleClassName
        smallClassName
        removedAt
      }
    }
  }
`;
export const listWastePackings = /* GraphQL */ `
  query ListWastePackings($sortDirection: ModelSortDirection) {
    listWastePackings(sortDirection: $sortDirection) {
      items {
        code
        name
        removedAt
      }
    }
  }
`;
export const listWasteQuantityUnits = /* GraphQL */ `
  query ListWasteQuantityUnits($sortDirection: ModelSortDirection) {
    listWasteQuantityUnits(sortDirection: $sortDirection) {
      items {
        code
        name
        removedAt
      }
    }
  }
`;
export const listWasteTransportMethods = /* GraphQL */ `
  query ListWasteTransportMethods($sortDirection: ModelSortDirection) {
    listWasteTransportMethods(sortDirection: $sortDirection) {
      items {
        code
        name
        removedAt
      }
    }
  }
`;
export const searchJwnetManifestsByOffset = /* GraphQL */ `
  query SearchJwnetManifestsByOffset(
    $filter: SearchableJwnetManifestFilterInput
    $sort: SearchableJwnetManifestSortInput
    $offset: Int
    $limit: Int
  ) {
    searchJwnetManifestsByOffset(
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        passedOverOn
        registeredOn
        lastUpdatedOn
        manifestNumber
        transportReportCompleted
        disposalReportCompleted
        lastDisposalReportCompleted
        manifestInfoTypeName
        wasteCategoryName
        wasteKind
        wasteName
        wasteQuantityValue
        wasteQuantityUnitName
        confirmedWasteQuantityValue
        confirmedWasteQuantityUnitName
        wasteDischargeCompanyName
        wasteDischargeWorkplaceName
      }
      nextToken
      total
    }
  }
`;
export const searchPaperManifestsByOffset = /* GraphQL */ `
  query SearchPaperManifestsByOffset(
    $filter: SearchablePaperManifestFilterInput
    $sort: SearchablePaperManifestSortInput
    $offset: Int
    $limit: Int
  ) {
    searchPaperManifestsByOffset(
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        issueDate
        issueNumber
        wasteGeneratorCompanyName
        wasteGeneratorWorkplaceName
        wasteTypeCode
        wasteTypeName
        wasteName
        wasteQuantityValue
        wasteQuantityUnitName
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchWasteTypesByOffset = /* GraphQL */ `
  query SearchWasteTypesByOffset(
    $filter: SearchableWasteTypeFilterInput
    $sort: SearchableWasteTypeSortInput
    $offset: Int
    $limit: Int
  ) {
    searchWasteTypesByOffset(
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        group {
          id
          name
          removedAt
        }
        removedAt
      }
      nextToken
      total
    }
  }
`;
export const getAlert = /* GraphQL */ `
  query GetAlert($alertTypes: [activeAlertTypes]!) {
    getAlert(alertTypes: $alertTypes) {
      wasteLicenseCount
      contractCount
      vehicleRemindCount
      missingCoordinateCount
      containerInstallationCount
      skippedTasksCount
    }
  }
`;
export const getApiClient = /* GraphQL */ `
  query GetApiClient($id: ID!) {
    getApiClient(id: $id) {
      id
      tenantId
      name
    }
  }
`;
export const getAuthenticatedUserAccount = /* GraphQL */ `
  query GetAuthenticatedUserAccount {
    getAuthenticatedUserAccount {
      id
      planSubscriberId
      name
      organizationName
      departments
      position
      email
      phone
      roleName
      state
    }
  }
`;
export const getDashboardWidgets = /* GraphQL */ `
  query GetDashboardWidgets {
    getDashboardWidgets {
      items {
        id
        name
        details
        layout {
          x
          y
          w
          h
          minW
          minH
          maxW
          maxH
          static
          isResizable
        }
      }
    }
  }
`;
export const getUserAccount = /* GraphQL */ `
  query GetUserAccount($id: ID!) {
    getUserAccount(id: $id) {
      id
      planSubscriberId
      name
      organizationName
      departments
      position
      email
      phone
      roleName
      state
    }
  }
`;
export const getUserDashboardWidgets = /* GraphQL */ `
  query GetUserDashboardWidgets {
    getUserDashboardWidgets {
      items {
        id
        layout {
          x
          y
          w
          h
          minW
          minH
          maxW
          maxH
          static
          isResizable
        }
        configurations
      }
    }
  }
`;
export const listApiClientsWithIAM = /* GraphQL */ `
  query ListApiClientsWithIAM(
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApiClientsWithIAM(
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tenantId
        name
      }
      nextToken
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles($sortDirection: ModelSortDirection) {
    listRoles(sortDirection: $sortDirection) {
      items {
        id
        name
        description
      }
    }
  }
`;
export const searchUserAccountsByOffset = /* GraphQL */ `
  query SearchUserAccountsByOffset(
    $state: SearchableUserAccountStatus
    $filter: SearchableUserAccountFilterInput
    $sort: SearchableUserAccountSortInput
    $offset: Int
    $limit: Int
  ) {
    searchUserAccountsByOffset(
      state: $state
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        name
        organizationName
        departments
        position
        email
        phone
        roleName
        state
      }
      nextToken
      total
    }
  }
`;
export const getIndustrialWasteLicense = /* GraphQL */ `
  query GetIndustrialWasteLicense($id: ID!) {
    getIndustrialWasteLicense(id: $id) {
      id
      number
      japaneseLocalGovernment {
        id
        prefectures {
          code
          name
          removedAt
        }
        cityCode
        checkDigit
        cityName
        removedAt
      }
      category {
        code
        order
        name
        removedAt
      }
      licensedOn
      licenseExpirationOn
      certifiedAsExcellentOn
      companyName
      representativeName
      postalCode
      prefectures {
        code
        name
        removedAt
      }
      city
      streetAddress
      otherAddress
      isMapInput
      remarks
      state
      licensedCompanyId
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const getMunicipalSolidWasteLicense = /* GraphQL */ `
  query GetMunicipalSolidWasteLicense($id: ID!) {
    getMunicipalSolidWasteLicense(id: $id) {
      id
      number
      japaneseLocalGovernment {
        id
        prefectures {
          code
          name
          removedAt
        }
        cityCode
        checkDigit
        cityName
        removedAt
      }
      category {
        code
        order
        name
        removedAt
      }
      licensedOn
      licenseExpirationOn
      companyName
      representativeName
      postalCode
      prefectures {
        code
        name
        removedAt
      }
      city
      streetAddress
      otherAddress
      remarks
      state
      licensedCompanyId
      files {
        name
        url
        order
        label
      }
      isMapInput
      version
    }
  }
`;
export const getTodayAssignedWasteCollectionScheduleCount = /* GraphQL */ `
  query GetTodayAssignedWasteCollectionScheduleCount {
    getTodayAssignedWasteCollectionScheduleCount
  }
`;
export const getTodayMyAssignedWasteCollectionScheduleInfo = /* GraphQL */ `
  query GetTodayMyAssignedWasteCollectionScheduleInfo {
    getTodayMyAssignedWasteCollectionScheduleInfo {
      total
      doneCount
      weightTotalOfKg
    }
  }
`;
export const getUnassignedWasteCollectionScheduleCount = /* GraphQL */ `
  query GetUnassignedWasteCollectionScheduleCount($date: String) {
    getUnassignedWasteCollectionScheduleCount(date: $date)
  }
`;
export const getWaste = /* GraphQL */ `
  query GetWaste($id: ID!) {
    getWaste(id: $id) {
      id
      number
      type {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        group {
          id
          name
          removedAt
        }
        removedAt
      }
      kind
      name
      packing {
        code
        name
        removedAt
      }
      packingDetails
      quantityUnit {
        code
        name
        removedAt
      }
      weightConvertedValue
      weightConvertedValueUnit {
        id
        order
        name
        removedAt
      }
      dischargeFrequency {
        interval {
          id
          order
          name
          removedAt
        }
        numberOfTimes
      }
      dischargeAmountPerOneTime
      isImportedWaste
      state
      dischargeSourceWorkplaceId
      version
    }
  }
`;
export const getWasteFlow = /* GraphQL */ `
  query GetWasteFlow($id: ID!) {
    getWasteFlow(id: $id) {
      id
      number
      name
      targetWasteType {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        group {
          id
          name
          removedAt
        }
        removedAt
      }
      targetWasteKind
      transportProcesses {
        section
        carrierCompany {
          id
          name
          phoneticGuidesOfName
        }
        transportMethod {
          code
          name
          removedAt
        }
        transportDestinationWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        version
      }
      disposalProcess {
        disposalCompany {
          id
          name
          phoneticGuidesOfName
        }
        disposalWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        disposalMethod {
          code
          largeClassName
          middleClassName
          smallClassName
          removedAt
        }
        disposalMethodDetails
        version
      }
      lastDisposalProcesses {
        disposalWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        version
      }
      state
      managedCompanyId
      version
    }
  }
`;
export const listIndustrialWasteLicenseBusinessCategories = /* GraphQL */ `
  query ListIndustrialWasteLicenseBusinessCategories(
    $sortDirection: ModelSortDirection
  ) {
    listIndustrialWasteLicenseBusinessCategories(
      sortDirection: $sortDirection
    ) {
      items {
        code
        order
        name
        removedAt
      }
    }
  }
`;
export const listJapaneseLocalGovernments = /* GraphQL */ `
  query ListJapaneseLocalGovernments(
    $sortDirection: ModelSortDirection
    $wasteLicenseGrantor: WasteLicenseGrantor
  ) {
    listJapaneseLocalGovernments(
      sortDirection: $sortDirection
      wasteLicenseGrantor: $wasteLicenseGrantor
    ) {
      items {
        id
        prefectures {
          code
          name
          removedAt
        }
        cityCode
        checkDigit
        cityName
        removedAt
      }
    }
  }
`;
export const listMunicipalSolidWasteLicenseBusinessCategories = /* GraphQL */ `
  query ListMunicipalSolidWasteLicenseBusinessCategories(
    $sortDirection: ModelSortDirection
  ) {
    listMunicipalSolidWasteLicenseBusinessCategories(
      sortDirection: $sortDirection
    ) {
      items {
        code
        order
        name
        removedAt
      }
    }
  }
`;
export const listOrdinanceDesignatedCityForWastes = /* GraphQL */ `
  query ListOrdinanceDesignatedCityForWastes(
    $sortDirection: ModelSortDirection
  ) {
    listOrdinanceDesignatedCityForWastes(sortDirection: $sortDirection) {
      items {
        japaneseLocalGovernmentId
        number
        removedAt
      }
    }
  }
`;
export const listUnassignedWasteCollectionSchedules = /* GraphQL */ `
  query ListUnassignedWasteCollectionSchedules(
    $offset: Int
    $limit: Int
    $sortDirection: ModelSortDirection
  ) {
    listUnassignedWasteCollectionSchedules(
      offset: $offset
      limit: $limit
      sortDirection: $sortDirection
    ) {
      items {
        scheduleDate
        unassignedCount
      }
    }
  }
`;
export const listWasteCollectionSchedulesCount = /* GraphQL */ `
  query ListWasteCollectionSchedulesCount(
    $offset: Int
    $limit: Int
    $sortDirection: ModelSortDirection
    $year: Int
    $month: Int
  ) {
    listWasteCollectionSchedulesCount(
      offset: $offset
      limit: $limit
      sortDirection: $sortDirection
      year: $year
      month: $month
    ) {
      items {
        scheduleDate
        unassignedCount
        assignedCount
        lastUpdatedOn
      }
      nextToken
      total
    }
  }
`;
export const listWasteDischargeIntervals = /* GraphQL */ `
  query ListWasteDischargeIntervals($sortDirection: ModelSortDirection) {
    listWasteDischargeIntervals(sortDirection: $sortDirection) {
      items {
        id
        order
        name
        removedAt
      }
    }
  }
`;
export const listWasteReportingMedia = /* GraphQL */ `
  query ListWasteReportingMedia($sortDirection: ModelSortDirection) {
    listWasteReportingMedia(sortDirection: $sortDirection) {
      items {
        id
        order
        name
        removedAt
      }
    }
  }
`;
export const listWasteReportingSettings = /* GraphQL */ `
  query ListWasteReportingSettings(
    $wasteManagementMethodId: ID!
    $sortDirection: ModelSortDirection
  ) {
    listWasteReportingSettings(
      wasteManagementMethodId: $wasteManagementMethodId
      sortDirection: $sortDirection
    ) {
      items {
        id
        validityStartOn
        validityEndOn
        reportingMedia
        wasteManagementMethodId
        version
      }
    }
  }
`;
export const listWeightConvertedValueUnits = /* GraphQL */ `
  query ListWeightConvertedValueUnits($sortDirection: ModelSortDirection) {
    listWeightConvertedValueUnits(sortDirection: $sortDirection) {
      items {
        id
        order
        name
        removedAt
      }
    }
  }
`;
export const searchWasteCollectionSchedulesByOffset = /* GraphQL */ `
  query SearchWasteCollectionSchedulesByOffset(
    $filter: SearchableWasteCollectionScheduleFilterInput
    $sort: SearchableWasteCollectionScheduleSortInput
    $offset: Int
    $limit: Int
    $searchQuery: String
  ) {
    searchWasteCollectionSchedulesByOffset(
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
      searchQuery: $searchQuery
    ) {
      items {
        id
        wasteGeneratorCompany {
          id
          name
          phoneticGuidesOfName
        }
        wasteCollectionWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        wasteCarrierCompany {
          id
          name
          phoneticGuidesOfName
        }
        waste {
          id
          number
          type {
            id
            largeClass {
              code
              name
              removedAt
            }
            middleClass {
              code
              name
              removedAt
            }
            smallClass {
              code
              name
              removedAt
            }
            group {
              id
              name
              removedAt
            }
            removedAt
          }
          kind
          name
          packing {
            code
            name
            removedAt
          }
          packingDetails
          quantityUnit {
            code
            name
            removedAt
          }
          weightConvertedValue
          weightConvertedValueUnit {
            id
            order
            name
            removedAt
          }
          dischargeFrequency {
            interval {
              id
              order
              name
              removedAt
            }
            numberOfTimes
          }
          dischargeAmountPerOneTime
          isImportedWaste
          state
          dischargeSourceWorkplaceId
          version
        }
        wasteQuantity
        scheduleDate
        scheduleTimeRangeStart
        scheduleTimeRangeEnd
        createdAt
        updatedAt
        version
      }
      nextToken
      total
    }
  }
`;
export const searchWasteFlowsByOffset = /* GraphQL */ `
  query SearchWasteFlowsByOffset(
    $managedCompanyId: ID!
    $filter: SearchableWasteFlowFilterInput
    $sort: SearchableWasteFlowSortInput
    $offset: Int
    $limit: Int
  ) {
    searchWasteFlowsByOffset(
      managedCompanyId: $managedCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        number
        name
        targetWasteType {
          id
          largeClass {
            code
            name
            removedAt
          }
          middleClass {
            code
            name
            removedAt
          }
          smallClass {
            code
            name
            removedAt
          }
          group {
            id
            name
            removedAt
          }
          removedAt
        }
        targetWasteKind
        transportSectionCount
        disposalProcess {
          disposalCompany {
            id
            name
            phoneticGuidesOfName
          }
          disposalWorkplace {
            id
            name
            phoneticGuidesOfName
            postalCode
            prefectures {
              code
              name
              removedAt
            }
            city
            streetAddress
            otherAddress
            email
            phone
            fax
            position {
              lat
              lng
            }
          }
          disposalMethod {
            code
            largeClassName
            middleClassName
            smallClassName
            removedAt
          }
          disposalMethodDetails
          version
        }
        state
        managedCompanyId
      }
      nextToken
      total
    }
  }
`;
export const searchWasteIncludingClientsByOffset = /* GraphQL */ `
  query SearchWasteIncludingClientsByOffset(
    $dischargeSourceWorkplaceId: ID
    $filter: SearchableWasteFilterInput
    $sort: SearchableWasteSortInput
    $offset: Int
    $limit: Int
  ) {
    searchWasteIncludingClientsByOffset(
      dischargeSourceWorkplaceId: $dischargeSourceWorkplaceId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        number
        type {
          id
          largeClass {
            code
            name
            removedAt
          }
          middleClass {
            code
            name
            removedAt
          }
          smallClass {
            code
            name
            removedAt
          }
          group {
            id
            name
            removedAt
          }
          removedAt
        }
        kind
        name
        packing {
          code
          name
          removedAt
        }
        packingDetails
        quantityUnit {
          code
          name
          removedAt
        }
        weightConvertedValue
        weightConvertedValueUnit {
          id
          order
          name
          removedAt
        }
        dischargeFrequency {
          interval {
            id
            order
            name
            removedAt
          }
          numberOfTimes
        }
        dischargeAmountPerOneTime
        flowCount
        reportingMedia
        isImportedWaste
        state
        dischargeSourceWorkplaceId
      }
      nextToken
      total
    }
  }
`;
export const searchWasteLicensesByOffset = /* GraphQL */ `
  query SearchWasteLicensesByOffset(
    $licensedCompanyId: ID
    $isFilteredForAlerts: Boolean
    $filter: SearchableWasteLicenseFilterInput
    $sort: SearchableWasteLicenseSortInput
    $offset: Int
    $limit: Int
  ) {
    searchWasteLicensesByOffset(
      licensedCompanyId: $licensedCompanyId
      isFilteredForAlerts: $isFilteredForAlerts
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        number
        japaneseLocalGovernment {
          id
          prefectures {
            code
            name
            removedAt
          }
          cityCode
          checkDigit
          cityName
          removedAt
        }
        categoryName
        licensedOn
        licenseExpirationOn
        certifiedAsExcellentOn
        companyName
        representativeName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        remarks
        state
        licensedCompanyId
        type
      }
      nextToken
      total
    }
  }
`;
export const searchWasteManagementMethodsByOffset = /* GraphQL */ `
  query SearchWasteManagementMethodsByOffset(
    $managedWasteId: ID!
    $filter: SearchableWasteManagementMethodFilterInput
    $sort: SearchableWasteManagementMethodSortableFields
    $offset: Int
    $limit: Int
  ) {
    searchWasteManagementMethodsByOffset(
      managedWasteId: $managedWasteId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        targetWasteType {
          id
          largeClass {
            code
            name
            removedAt
          }
          middleClass {
            code
            name
            removedAt
          }
          smallClass {
            code
            name
            removedAt
          }
          group {
            id
            name
            removedAt
          }
          removedAt
        }
        transportSectionCount
        disposalProcess {
          disposalCompany {
            id
            name
            phoneticGuidesOfName
          }
          disposalWorkplace {
            id
            name
            phoneticGuidesOfName
            postalCode
            prefectures {
              code
              name
              removedAt
            }
            city
            streetAddress
            otherAddress
            email
            phone
            fax
            position {
              lat
              lng
            }
          }
          disposalMethod {
            code
            largeClassName
            middleClassName
            smallClassName
            removedAt
          }
          disposalMethodDetails
          version
        }
        reportingMedia
        state
        wasteFlowId
        managedWasteId
      }
      nextToken
      total
    }
  }
`;
