import {
  updateChecksheetTemplate,
  removeChecksheetTemplate,
} from "api/graphql/mutations";
import { getChecksheetTemplate } from "api/graphql/queries";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "utils/useMutation";
import { useOpener } from "utils/useOpener";
import { useQuery } from "utils/useQuery";

/**
 * チェックシートテンプレート詳細画面を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const form = useOpener();
  const confirm = useOpener();

  const checksheetsTemplate = useQuery({
    query: getChecksheetTemplate,
    variables: {
      id: id,
    },
  });

  const [updateMutation, updateMutationStatus] = useMutation(
    updateChecksheetTemplate,
    {
      onCompleted: (data) => {
        checksheetsTemplate.refetch();
        form.toggle();
      },
      succeedMessage: "保存しました。",
      errorMessage: "エラーが発生したため、保存できませんでした。",
    }
  );

  const [removeMutation, removeMutationStatus] = useMutation(
    removeChecksheetTemplate,
    {
      onCompleted: (data) => {
        navigate(
          {
            pathname: `/master/checksheets`,
          },
          {
            replace: true,
          }
        );
      },
      succeedMessage: "削除しました。",
      errorMessage: "エラーが発生したため、削除できませんでした。",
    }
  );

  const handleUpdate = async (data) => {
    const { name, description, remarks, sections } = data;

    updateMutation({
      input: {
        id: id,
        name: name,
        description: description,
        remarks: remarks,
        sections: sections.map((section) => ({
          ...section,
          items:
            section.items?.map((item) => ({
              id: item?.id,
              name: item?.name,
            })) ?? [],
        })),
        expectedVersion:
          checksheetsTemplate?.data?.getChecksheetTemplate?.version,
      },
    });
  };

  const hanldeRemove = () => {
    removeMutation({
      input: {
        id: id,
      },
    });
  };

  return render({
    ...props,
    value: checksheetsTemplate?.data?.getChecksheetTemplate,
    id: id,
    isSubmit:
      updateMutationStatus.loading ||
      checksheetsTemplate.loading ||
      removeMutationStatus.loading,
    onUpdate: handleUpdate,
    onRemove: hanldeRemove,
    form: form,
    confirm: confirm,
  });
};
